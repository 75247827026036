/* =========== КОНСТАНТЫ =========== */
const currency_symbols = {
    'USD': '$',
    'EUR': '€',
    'RUB': '₽'
};

// Names инпутов, которые не распространяются на сортировку (обработку RitgGrid.js)
const filterFieldsWithoutSort = [
    'promoCheck',
    'mainFormAnnualPolicy',
    'additionalOptionsProfessionalSport',
    'mainFormAlreadyTraveling'
];
/* ================================= */

/* CHECKBOX ----------------------- */
// Простые
function functionCheckboxDefault (item) {
    //Простановка галочки в чекбоксах
    let divMark = item.closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('.checkbox__mark'));
    let inputElement = item.closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('input'));
    if (divMark.hasClass('checked')) {
        divMark.removeClass('checked');
        inputElement.attr('value', 'false');
    }
    else {
        divMark.addClass('checked');
        inputElement.attr('value', 'true');
    }
}
function filterOptions(item) {
    let divMark = item.closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('.checkbox__mark'));
    let name = item.closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('input')).attr('name');

    // Проверка подходимости поля для сортировки (если нашлось в константах, то пропускаем)
    if ($.inArray(name, filterFieldsWithoutSort) == -1) {
        var element = item.closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('input'));
        if (typeof (viewData) != undefined && $(divMark).hasClass('checked'))
            viewData.setFilter($(element).attr("data-info"), $(element).attr("data-type-check"), true);
        else
            viewData.setFilter($(element).attr("data-info"), $(element).attr("data-type-check"), false);
    }
}

$('[data-checkbox-default]').on('click', function () {
    functionCheckboxDefault ($(this));
});

$('[data-checkbox-filter]').on('click', function () {
    functionCheckboxDefault ($(this));
    filterOptions($(this));
    let nameCheckbox = $(this).parent('div').children('input').attr('name');
    let infoName = $(this).siblings('input').attr('data-info');
    let typeName = $(this).siblings('input').attr('data-type-check');
    let checkedValue = false;
    if ($(this).siblings('input').val() == "true") {
        checkedValue = true;
    }
    else if ($(this).siblings('input').val() == "false") {
        checkedValue = false;
    }
    $.ajax({
        url: "/local/php_interface/ajax/save_options.php",
        type: "GET",
        data: { options: {name: nameCheckbox, checked: checkedValue, filterValue: infoName, type: typeName} },
        dataType: "html",
        cache: false,
    });
});

// "Годовой полис"
$('[data-checkbox-policy]').on('click', function () {
    let divMark = $(this).closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('.checkbox__mark'));
    let inputElement = $(this).closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('input'));
    let name = $(this).closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('input')).attr('name');

    if (divMark.hasClass('checked')) {
        // ГодовойПолис = false
        divMark.removeClass('checked');
        inputElement.removeAttr('checked').val('false');
        $('[data-datepicker-trip-from]').attr('name', 'mainFormDateFrom');
        $('[data-period-date]').each(function () {
            $(this).show();
        });
        $('[data-year-date]').each(function () {
            $(this).hide();
        });
        $('[data-period-from]').addClass('flying_label__input from left').attr('name', 'mainFormDateFrom').removeAttr('placeholder');
        flyingLabelInput($('[data-period-from]'));
        $('[data-period-from]').on('change', function () {
            flyingLabelInput($(this));
        });
        $('[data-period-from]').on('focus', function () {
            flyingLabelInput($(this));
        });
        $('[data-period-from]').closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('.flying_label__label')).on('click', function () {
            $('[data-period-from]').focus();
        });
    } else {
        // ГодовойПолис = true
        divMark.addClass('checked');
        inputElement.attr('checked', 'checked').val('true');
        $('[data-datepicker-trip-from]').attr('name', 'mainFormDateFromYear');
        $('[data-period-date]').each(function () {
            $(this).hide();
        });
        $('[data-year-date]').each(function () {
            $(this).show();
        });
        $('[data-period-from]').removeClass('flying_label__input from left').attr('name', 'mainFormDateFromYear').attr('placeholder', 'Дата начала');
    }

    // Проверка подходимости поля для сортировки (если нашлось в константах, то пропускаем)
    if ($.inArray(name, filterFieldsWithoutSort) == -1) {
        var element = $(this).closest('.checkbox__dark, .checkbox__light, .checkbox__purple').find($('input')).get(0);
        if (typeof (viewData) != undefined && $(divMark).hasClass('checked'))
            viewData.setFilter($(element).attr("data-info"), $(element).attr("data-type-check"), true);
        else
            viewData.setFilter($(element).attr("data-info"), $(element).attr("data-type-check"), false);
    }
});

// чекбоксы-соцсети для раздела СОС
$('.social_network').on('click', function () {
    let number = $(this).attr('data-js-number');
    if ($(this).prev($('[type="checkbox"]')).attr('checked')) {
        $(this).prev($('[type="checkbox"]')).removeAttr('checked');
        $(this).removeClass('active');

        let count = 0;
        $('.social_network__' + number).find($('[type="checkbox"]')).each(function () {
            if ($(this).attr('checked')) count++;
        });
        if (count == 0) $('.social_network__' + number).find($('.customer-data__vertical-line')).removeClass('active');
    } else {
        $(this).prev($('[type="checkbox"]')).attr('checked', 'checked');
        $(this).addClass('active');
        $('.social_network__' + number).find($('.customer-data__vertical-line')).addClass('active');
    }
});
/* -------------------------------- */


/* КАЛЕНДАРЬ ---------------------- */
var dateToday = new Date();
var rangeOfBirthDate = (dateToday.getFullYear() - 100) + ':' + (dateToday.getFullYear());
var rangeOfBirthDateBuyer = (dateToday.getFullYear() - 100) + ':' + (dateToday.getFullYear() - 18);
dateToday.setTime(dateToday.getTime() + 86400000);

$( function() {
    $('[data-datepicker]').mask('99.99.9999').datepicker({
        firstDay: 1,
        showOn: 'both',
        dateFormat: 'dd.mm.yy',
        showOtherMonths: true,
        minDate: '-10Y',
        buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
        buttonImageOnly: true,
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
        dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        changeMonth: true,
        changeYear: true
    });

    var datepickerBirthdayArray = {
        firstDay: 1,
        showOn: 'both',
        dateFormat: 'dd.mm.yy',
        showOtherMonths: true,
        minDate: '-100Y',
        maxDate: '0',
        yearRange: rangeOfBirthDate,
        buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
        buttonImageOnly: true,
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
        dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        changeMonth: true,
        changeYear: true
    };
    $('[datepicker-birthday]').each(function () {
        if ($(this).attr('data-buyer') == 'true') {
            datepickerBirthdayArray.yearRange = rangeOfBirthDateBuyer;
            datepickerBirthdayArray.maxDate = '-18Y';
        } else {
            datepickerBirthdayArray.yearRange = rangeOfBirthDate;
            datepickerBirthdayArray.maxDate = '0';
        }
        $(this).mask('99.99.9999').datepicker(datepickerBirthdayArray);
    });

    let startTrip = $('[data-datepicker-custom-start]').mask('99.99.9999').each(function () {
        let startDate = $(this).attr('data-min-date');
        $(this).datepicker({
           firstDay: 1,
           showOn: 'both',
           dateFormat: 'dd.mm.yy',
           showOtherMonths: true,
           minDate: startDate,
           maxDate: '0',
           buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
           buttonImageOnly: true,
           monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
               'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
           monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
               'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
           dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
           dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
           dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
           changeMonth: false,
           changeYear: false
        }).on( "change", function() {
            incident.datepicker( "option", "minDate", getDate( this ) );
        });
    });
    let incident = $('[data-datepicker-case]').mask('99.99.9999').each(function () {
        $(this).datepicker({
            firstDay: 1,
            showOn: 'both',
            dateFormat: 'dd.mm.yy',
            showOtherMonths: true,
            maxDate: '0',
            buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
            buttonImageOnly: true,
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            changeMonth: false,
            changeYear: false
        });
    }).on( "change", function() {
        startTrip.datepicker( "option", "maxDate", getDate( this ) );
    });

    // Настройка поле туда и обратно в калькуляторе
    var dateFormat = "dd.mm.yy",
    from = $('[data-datepicker-trip-from]').mask('99.99.9999').datepicker({
        firstDay: 1,
        showOn: 'both',
        dateFormat: 'dd.mm.yy',
        showOtherMonths: true,
        minDate: '+1D',
        buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
        buttonImageOnly: true,
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
        dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    }).on( "change", function() {
        to.datepicker( "option", "minDate", getDate( this ) );
    }),
    to = $('[data-datepicker-trip-to]').mask('99.99.9999').datepicker({
        firstDay: 1,
        showOn: 'both',
        dateFormat: 'dd.mm.yy',
        showOtherMonths: true,
        buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
        buttonImageOnly: true,
        minDate: '0',
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
        dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    }).on( "change", function() {
        from.datepicker("option", "maxDate", getDate(this));
    });

    function getDate( element ) {
        var date;
        try {
            date = $.datepicker.parseDate( dateFormat, element.value );
        } catch ( error ) {
            date = null;
        }
        return date;
    }

    Date.prototype.toInputFormat = function() {
        var yyyy = this.getFullYear().toString();
        var mm = (this.getMonth() + 1).toString();
        var dd  = this.getDate().toString();
        return (dd[1] ? dd : "0" + dd[0]) + "." + (mm[1] ? mm : "0" + mm[0]) + "." + yyyy;
    };
});

$(function() {
    // смена цвета иконки
    $('.hasDatepicker').on('focus', function () {
        const input = $(this);
        const img = $(this).parent().find($('.ui-datepicker-trigger'));
        const label = $(this).parent().find($('label'));

        if (!input.hasClass('error')) img.attr('src', '/local/templates/saveprolife/build/images/icon-date-active.svg');

        input.focusout(function () {
            img.attr('src', '/local/templates/saveprolife/build/images/icon-date.svg');
        });
    }).attr({
        ondragstart: 'return false;',
        ondrop: 'return false;',
        autocomplete: 'off'
    });
});
/* --------------------------------- */

/* РАСКРЫВАЮЩИЕСЯ СПИСКИ ----------- */
if ($('.dropdown_value').text().replace(/\s+/g, '') == '') $('.dropdown_value').text($('.dropdown-item:first-child').text());
$('.dropdown-item').on({
    'click': function () {
        let name = $(this).attr('name');
        let text = $(this).text();
        let value = $(this).attr('id').replace((name + '_'), '');
        $('.dropdown_value' + '[name=' + name + ']').text(text);
        $('input[name=' + name + ']').attr('value', value);
        $('.dropdown[name=' + name + ']').addClass('selected');
    }
});

$('[data-dropdown-selecting]').on('click', function () {
    let input = $(this).parent().find($('input[type="text"]'));
    let currency = currency_symbols[input.attr('data-currency')];
    let inputSend = $(this).parent().find($('[type=hidden]'));
    let list = $(this).parent().find($('[data-dropdown-default]'));
    let button = $(this).parent().find($('button'));

    list.attr('style', 'display: flex;');
    list.children('a').on('click', function () {
        let value = $(this).text();
        list.hide();
        input.val(value);
        let newValue = value.replace(currency, '').replace(/,/, '.').replace(/\s+/g, '').trim();
        inputSend.attr('value', newValue).val(newValue);
        if (input.parent($('.dropdown_select')).hasClass('error')) input.parent($('.dropdown_select')).removeClass('error');
        input.parent($('.dropdown_select')).addClass('selected');
        input.parent($('.dropdown_select')).find('button').attr('data-delete', "true");
    });
    button.addClass('button_up');

    $(document).mouseup(function (e) {
        if (!list.is(e.target)) {
            list.hide();
            button.removeClass('button_up');
        }
    });
});

$('.dropdown_value').focusin(function (e) {
    e.preventDefault();
    let inputSend = $(this).prev($('[type=hidden]'));
    let value = inputSend.attr('value');
    let parent = $(this).parent($('.dropdown_select'));

    if (parent.hasClass('error')) parent.removeClass('error');

    if (value) {
        $(this).val(value);
    }

    return false;
});

$('.dropdown_value').focusout(function () {
    let value = Number($(this).val().replace(/,/, '.'));
    let inputSend = $(this).prev($('[type=hidden]'));
    let parentDiv = $(this).parent($('.dropdown_select'));
    let minValue = $(this).attr('min');
    let maxValue = $(this).attr('max');
    let currency = currency_symbols[$(this).attr('data-currency')];

    if (value) {
        let newValue = value.toLocaleString() + ' ' + currency;
        if ((value < minValue) || (value > maxValue)) {
            parentDiv.addClass('error');
            if (parentDiv.hasClass('selected')) parentDiv.removeClass('selected');
        }
        else {
            parentDiv.addClass('selected');
            if (parentDiv.hasClass('error')) parentDiv.removeClass('error');
        }
        parentDiv.find('button').attr('data-delete', "true");
        $(this).val(newValue);

        inputSend.attr('value', value);
    }
    else {
        parentDiv.removeClass('selected');
        parentDiv.find('button').attr('data-delete', "false");
    }
});

// Ограничение ввода - только числа
$(function () {
    $(document).on("change keyup keydown mousedown mouseup select contextmenu drop input click", "input[data-only-numbers]", function() {
        if(this.value.match(/[^./0-9]/g)){
            this.value = this.value.replace(/[^./0-9]/g, "");
        }
    });
});

/* ------- RADIO BUTTONS ------- */
$(function () {
    radioDefault ();
});
$('[data-radio]').on('click', function (e) {
    let element = $(this);
    e.preventDefault();
    let name = element.attr('data-id');
    let value = $(this).attr('data-value');
    if (value) {
        $('[type="radio"][name=' + name + ']').each(function () {
            $(this).removeAttr('checked');
        });
        $('#radio_' + value + '[name=' + name + ']').attr('checked', 'checked');
    }
    if (element.attr('data-url')) {
        let url = element.attr('data-url') + '/newFilteredData.php';
        let originalUrl = element.attr('data-original-url');
        let valueType = element.attr('data-send-type');
        let valueLetter = element.attr('data-send-letter');
        let idValue = element.attr('data-id');
        let sendTo = $('[data-send-to-id=' + idValue + ']');
        $.ajax({
            url: url,
            type: "GET",
            data: { type: valueType, letter: valueLetter, original: originalUrl },
            dataType: "html",
            cache: false,
            success: function (data) {
                $('[data-radio][data-id=' + name + ']').each(function () {
                    $(this).removeClass('checked');
                });
                element.addClass('checked');
                sendTo.html($(data));
            }
        });
    } else {
        $('[data-radio][data-id=' + name + ']').each(function () {
            $(this).removeClass('checked');
        });
        element.addClass('checked');
    }
    return false;
});
// Общий метод по добавлению radio атрибута 'checked' - необходимо для корректной работы других методов с radio
function radioDefault () {
    $('.radio_element').find($('label')).each(function () {
        if (!$(this).closest($('.radio_element')).find($('[type="radio"]')).attr('onchange')) {
            $(this).on('click', function () {
                radioChangeFunction ($(this));
            });
        }
    });
}
function radioChangeFunction (element) {
    let name = element.closest($('.radio_element')).find($('[type="radio"]')).attr('name');
    $('[name="' + name + '"]').each(function () {
        $(this).removeAttr('checked');
    });
    let input = element.closest($('.radio_element')).find($('[type="radio"]'));
    if (!input.attr('checked')) input.attr('checked', true);
}

/* ------ ПРИКРЕПИТЬ ФАЙЛ ------ */
$(function () {
    $('[data-attach-file]').on('change', function (e) {
        if (e.target.value) {
            let fileName = e.target.value.split("\\").pop();
            $('[for="file-input"]').text(fileName);
        }
    });
});

/* ----- ОБРАБОТКА ОШИБОК ------ */
// Удаление сообщения об ошибке после нажатия на input
/*$('input').on('click', function () {
    if ($(this).hasClass('error')) {
        $(this).removeClass('error');
    }
});*/
$('.selected-polis__promocod-box').find($('[type=text]')).keydown(function () {
    let promocode = $(this).parent($('.row')).parent($('selected-polis__promocod-box'));
    if (promocode.hasClass('error')) {
        promocode.removeClass('error');
    }
    if ($('[data-activate-promocode]').attr('disabled')) $('[data-activate-promocode]').removeAttr('disabled');
});

/* ------- ОТКРЫТЬ ВИДЕО ------- */
$('[data-play-video]').on('click', function (e) {
    e.preventDefault();
    let sendTo = $('.video_player_cover');
    let url = 'https://www.youtube-nocookie.com/embed/' + $(this).attr('data-id') + "?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1";
    $.ajax({
        url: '/local/templates/saveprolife/components/bitrix/news.list/video_block/videoIframe.php',
        type: "GET",
        data: { video: url },
        dataType: "html",
        cache: false,
        success: function (data) {
            sendTo.text('');
            sendTo.append($(data));
        }
    });
});

$(function () {
    $('.flying_label__input').each(function () {
        flyingLabelInput($(this));
    });
});

$('.flying_label__input').on('change', function () {
    flyingLabelInput($(this));
});

$('.flying_label__input').on('focus', function () {
    flyingLabelInput($(this));
});

$('.flying_label__label').on('click', function () {
    $(this).parent().find($('.flying_label__input')).focus();
});

/* -- INPUT'Ы С ЛЕТАЮЩИМИ LABEL'АМИ -- */
function flyingLabelInput (element) {
    let labelElement = element.parent().find($('.flying_label__label'));
    if ((element.val()) && !(element.hasClass('hasDatepicker') && (element.val().indexOf('_') + 1) > 0)) labelElement.addClass('label_up');
    else if (labelElement.hasClass('label_up')) labelElement.removeClass('label_up');
}

// Для отключения кэширования данных у input
$('input').attr('autocomplete','randomString');
$('form').attr('autocomplete','randomString');

/* ----------- ЗАПРЕТ НА ВВОД ----------- */

function writeOnlyType (element) {
    let typeOfData = element.attr('data-type').split(' ');
    let pattern = '';
    $.each(typeOfData, function (i, value) {
        switch (value) {
            case 'ru':
                pattern += 'а-яА-ЯёЁ';
                break;
            case 'en':
                pattern += 'a-zA-Z';
                break;
            case 'num':
                pattern += '0-9';
                break;
            case 'dash':
                pattern += '-';
                break;
            case 'space':
                pattern += ' ';
                break;
        }
    });
    pattern = pattern ? new RegExp("^[" + pattern + "]") : '';
    return pattern;
}

$(function () {
    // readonly
    $('[data-js-read-only]').attr('onkeydown', 'return false');
    // Ввод только русских / английский букв / цифр
    $('[js-write-only]').on('keypress', function(e) {
        let pattern = writeOnlyType ($(this));
        if (pattern && !pattern.test(String.fromCharCode(e.which))) {
            return false;
        }
    });
});

// форматирование текста (имён/фамилий) в PascalCase
$('[js-pascal-case-format]').on('focusout', function () {
    formatingPascalCase ($(this));
});

function formatingPascalCase (element) {
    let valueOrigin = element.val();
    let newValue = valueOrigin.substr(0, 1).toUpperCase();
    newValue += valueOrigin.charAt(1);
    for (let i = 2; i < valueOrigin.length; i++) {
        let char = valueOrigin.charAt(i);
        let prevChar = valueOrigin.charAt(i - 1);
        if (prevChar == ' ' || prevChar == '-') char = char.toUpperCase();
        newValue += char;
    }
    element.val(newValue);
}

$(document).ready(function () {

    $('.register_person').on('input', function () {
        $(this).removeClass('error');
        $('.text_error').each(function () {
            $(this).hide();
        });
        $('.error-panel-auth').hide();
    });

    function trimmedLogin(){
        const trimmed = $('#login').val().trim();
        $('#login').val(trimmed);
    }

    //AJAX-запрос на проверку существования пользователя
    $('#checkEmail').on("click", function () {
        trimmedLogin();
        $('#login').removeClass('error');
        $('.error-panel-email').hide();
        if ($('#login').val().match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\[.]{1}[a-z]{2,}$')) {
            $('input[name="register_submit_button"]').prop("disabled", true);
            $('input[name="auth_submit_button"]').prop("disabled", true);
            let email = $('input[name="USER_LOGIN"]').val();
            $.ajax({
                url: "/local/php_interface/ajax/auth.php",
                type: "POST",
                data: {
                    'USER_EMAIL': email,
                    'AUTH_ACTION': 'CHECK_EMAIL'
                },
                cache: true,

                dataType: 'json',
                success: function (data) {
                    if (data['status'] === 200) {
                        //Выбор авторизации или регистрации
                        switch (data['email']) {
                            case 'y':
                                if ($('.autorization-block__registration').is(':visible')) {
                                    $('.autorization-block__registration').hide();
                                }
                                $('.autorization-block__login').show();
                                $('input[name="auth_submit_button"]').prop("disabled", false);
                                $('#checkEmail').parent().hide();

                                break;
                            case 'n':
                                if ($('.autorization-block__login').is(':visible')) {
                                    $('.autorization-block__login').hide();
                                }
                                $('.autorization-block__registration').show();
                                $('#checkEmail').parent().hide();
                                break;
                        }
                    }
                },
            });
        } else {
            $('#login').addClass('error');
            $('.error-panel-email').show();
        }
    });

    //AJAX-запрос на авторизацию пользователя
    $('input[name="auth_submit_button"]').click(function (e) {
        trimmedLogin();
        e.preventDefault();
        let email = $('input[name="USER_LOGIN"]').val();
        let password = $('input[name="PASSWORD"]').val();
        let nextUrl = $(this).attr('data-next-url');
        $('#login').removeClass('error');
        $('.password').removeClass('error');
        // $('.error-panel-auth').hide();
        $.ajax({
            type: "POST",
            url: "/local/php_interface/ajax/auth.php",
            data: {
                'USER_LOGIN': email,
                'USER_PASSWORD': password,
                'AUTH_ACTION': 'AUTHORIZATION'
            },
            dataType: 'json',
            success: function (data) {
                if (data['status'] === 200) {
                    window.location = nextUrl ? nextUrl : '/cabinet/insurance-claims/';
                } else if (data['status'] === 403) {
                    $('#login').addClass('error');
                    $('.password').addClass('error');
                    $('.error-panel-auth').show();
                    $('.error-panel-text').text(data['message']);
                } else if (data['status'] === 0) {
                }
            },
            error: function (jqXHR, exception) {
            }
        });
    });

    //AJAX-запрос на регистрация пользователя
    $('input[name="register_submit_button"]').click(function (e) {
        trimmedLogin();
        e.preventDefault();
        let email = $('input[name="USER_LOGIN"]').val();
        let name = $('input[name="REGISTER[NAME]"]').val();
        let password = $('input[name="REGISTER[PASSWORD]"]').val();
        let nextUrl = $(this).attr('data-next-url');
        $.ajax({
            type: "POST",
            url: "/local/php_interface/ajax/auth.php",
            data: {
                'EMAIL': email,
                'LOGIN': email,
                'PASSWORD': password,
                'CONFIRM_PASSWORD': password,
                'NAME': name,
                'register_submit_button': true,
                'AUTH_ACTION': 'REGISTRATION'
            },
            dataType: 'json',
            success: function (data) {
                if (data['status'] === 200) {
                    window.location = nextUrl ? nextUrl : '/cabinet/profile/';
                } else if (data['status'] === 403) {
                    $( "input" ).each(function( index ) {
                        if($(this).val() === '') {
                            $(this).addClass('error');
                        } else if ($(this).attr('name') == 'REGISTER[PASSWORD]' && $(this).val().length < 6) {
                            $(this).parent().find($('.text_error')).show();
                            $(this).addClass('error');
                        }
                    });
                    $('.error-panel-auth').show();
                    $('.error-panel-text').text('Заполните все поля');
                } else if (data['status'] === 0) {
                    $('.error-panel-auth').show();
                    $('.error-panel-text').text('Заполните все поля');
                }
            },
            error: function (jqXHR, exception) {
            },
        });
    });
});

/* ПОЛЕ "ДОБАВИТЬ ТУРИСТА" - возраст */
function newPerson (i) {
    var newPerson = '<div class="dropdown_element" id="dropdown_person_' + i + '_block">' +
        '<div class="dropdown_element_number">' +
        i +
        '</div>' +
        '<input autocomplete="off" type="text" class="input_common YearsOld" placeholder="Возраст" ' +
        'onchange="changePerson(this);" name="mainFormYearsOld[]" ' +
        'oninput="this.value = this.value.replace(/[^\\d]/, \'\'); searchNumber(this);" maxlength="2">' +
        '<button class="delete_person" id="dropdown_person_' + i + '" onClick="deletePerson(this)">' +
        '<img src="/local/templates/saveprolife/build/images/icon-cross.svg" alt="icon_cross">' +
        '</button>' +
        '</div>';
    $('.dropdown_people').append(newPerson);
}

var peopleCounter = Number($('[data-count-tourist]').text().replace(/[^0-9.]/g, ""));
// Добавление ещё одного input'а при клике на кнопку "+ Добавить":
$('[data-add-person]').on('click', function (e) {
    e.preventDefault();
    let countTourists
    if (Number($('[data-count-tourist]').text().replace(/[^0-9.]/g, "")) == 0){
        countTourists = 1;
    }
    else {
        countTourists = Number($('[data-count-tourist]').text().replace(/[^0-9.]/g, ""));
    }

    peopleCounter = countTourists;
    peopleCounter++;
    // Ограничение по количеству застрахованных - 5
    if (peopleCounter < 6) {
        newPerson(peopleCounter);
        updateCountTourist(peopleCounter);
        $('.delete_person').addClass('show');
    }
});

// Добавление кнопки удаления застрахованного, если при прогрузке страницы больше 1 человека
$('[data-count-tourist]').on('click', function (e){
    if (peopleCounter > 1) {
        $('.delete_person').addClass('show');
    }
});

// Добавление input'ов для дополнительного туриста при клике на кнопку "+ добавить участника" (форма оформления полиса):
$('[js-add-additional-tourist]').on('click', function (e) {
    e.preventDefault();
    let button = $(this);
    let touristCount = Number(button.attr('data-count-of-tourists')) + 1;
    let gender = button.attr('data-gender');
    let checkbox = button.attr('data-checkbox');
    let policy = button.attr('data-policy-type');
    let country = button.attr('data-country-id');
    let price = button.attr('data-price-value');
    let touristOriginCount = Number($('[data-all-tourists]').find('[data-tourist]').last().attr('data-tourist-block-origin-id')) + 1;
    button.attr('data-count-of-tourists', touristCount);
    $.ajax({
        url: '/policy/service/addTourist.php',
        type: "POST",
        data: {
            touristCount: touristCount,
            touristOriginCount: touristOriginCount,
            gender: gender,
            checkbox: checkbox,
            policy: policy,
            country: country,
            price: price
        },
        dataType: "html",
        cache: false,
        success: function (data) {
            $('[data-all-tourists]').append($(data));
            $('[data-pay-text]').text('Пересчитать');
            $('#isRecalc').val('true');
            $('[data-tourist]').each(function () {
                if ($(this).find($('[js-delete-tourist]')).attr('style'))$(this).find($('[js-delete-tourist]')).removeAttr('style');
            });

            if (policy == 'ERV') {
                let newRadioTourist = '<div js-radio-tourist data-id-tourist="' + touristCount + '" class="radio_element">' +
                        '<input type="radio" id="buyer_' + touristCount + '" data-radio-value="' + touristCount + '" name="buyer_radio_tourists" onchange="radioTouristsChange($(this))" autocomplete="randomString">' +
                        '<label for="buyer_' + touristCount + '">Турист ' + touristCount + '</label>' +
                    '</div>';
                $('[data-radio-tourists]').append(newRadioTourist);
            }

            // Далее и до конца - перезагрузка функций, используемых в новом блоке с туристом
            $('[datepicker-birthday]').mask('99.99.9999').datepicker({
                firstDay: 1,
                showOn: 'both',
                dateFormat: 'dd.mm.yy',
                showOtherMonths: true,
                minDate: '-100Y',
                maxDate: '0',
                yearRange: rangeOfBirthDate,
                buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
                buttonImageOnly: true,
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
                dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
                dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                changeMonth: true,
                changeYear: true
            });
            // смена цвета иконки
            $('[datepicker-birthday]').on('focus', function () {
                const input = $(this);
                const img = $(this).parent().find($('.ui-datepicker-trigger'));
                const label = $(this).parent().find($('label'));

                if (!input.hasClass('error')) img.attr('src', '/local/templates/saveprolife/build/images/icon-date-active.svg');

                input.focusout(function () {
                    img.attr('src', '/local/templates/saveprolife/build/images/icon-date.svg');
                });
            });
            // "летающие" label'ы
            $('.flying_label__input').on('change', function () {
                flyingLabelInput($(this));
            });
            $('.flying_label__input').on('focus', function () {
                flyingLabelInput($(this));
            });
            // readonly
            $('[data-js-read-only]').attr('onkeydown', 'return false');
            // Ввод только русских / английский букв / цифр
            $('[js-write-only]').on('keypress', function(e) {
                let pattern = writeOnlyType ($(this));
                if (pattern && !pattern.test(String.fromCharCode(e.which))) {
                    return false;
                }
            });
            // механизм работы checkbox'ов
            $('[data-checkbox-default]').on('click', function () {
                functionCheckboxDefault ($(this));
            });
            // Общий метод по добавлению radio атрибута 'checked' - необходимо для корректной работы других методов с radio
            radioDefault ();

            // Передача данных из input'ов с туристом в input'ы с покупателем
            // - если выбран один из туристов в radio
            // - если поле с выбранным туристом изменилось
            $('#ERVForm').on('keyup focusout', 'input:not(.hasDatepicker)', function () {
                const idTourist = $('[data-all-tourists]').attr('data-selected-tourist');
                const idBlock = $(this).closest($('[data-tourist]')).attr('data-tourist-block-id');
                if (idTourist == idBlock) {
                    fromTouristToBuyer ($(this))
                }
            });

            // форматирование текста (имён/фамилий) в PascalCase
            $('[js-pascal-case-format]').on('focusout', function () {
                formatingPascalCase ($(this));
            });
        }
    });
    if (touristCount == 5) {
        button.hide();
    }
    return false;
});

// Функция удаление input'а при клике на кнопку "х":
function deletePerson (element) {
    let el = '#' + $(element).attr('id') + '_block';
    $(el).remove();
    if ($('.delete_person').length == 1) {
        $('.delete_person').removeClass('show');
    }
    // Переделать for и eq в функцию .each()
    $('.dropdown_element').each(function (i){
        $(this).attr('id', 'dropdown_person_' + (i + 1) + '_block');
        $(this).find('[js-delete-person]').attr('id', 'dropdown_person_' + (i + 1));
        $(this).find('.dropdown_element_number').text(i + 1);
        $(this).find('.delete_person').attr('id', 'dropdown_person_' + (i + 1));
        peopleCounter = i + 1;
        i++;
    });
    updateCountTourist(peopleCounter);
}

// Удаление input'ов туриста при клике на кнопку "удалить х" (форма оформления полиса):
function deleteTourist (element, policy) {
    let idElement = element.attr('data-tourist-id');
    let deletedBlock = '[data-tourist-block-id=' + idElement + ']';
    let deletedRadioBlock = '[data-id-tourist=' + idElement + ']';
    $(deletedBlock).remove();
    $(deletedRadioBlock).remove();
    $('[data-pay-text]').text('Пересчитать');
    $('#isRecalc').val('true');
    let counter = 0;
    $('[data-tourist]').each(function () {
        counter++;
        let tourist = $(this);
        tourist.attr('data-tourist-block-id', counter);
        tourist.find($('.customer-data__tourist-label')).text('Турист ' + counter + '.');
        tourist.find($('[js-delete-tourist]')).attr('data-tourist-id', counter);
        tourist.find($('input')).each(function () {
            let input = $(this);
            let inputId = input.attr('id');
            let inputName = input.attr('name');
            let newInputId = inputId ? inputId.substr(0, inputId.length-1) + counter : inputId;
            let inputNameParsed = [];
            if (inputName) {
                inputName.split('[').forEach((element) => {
                    inputNameParsed.push(element.replace(/]/, ''));
                });
            }
            let newInputName = inputName;
            if (newInputName) {
                inputNameParsed.forEach(function (value, index) {
                    if (index == 0) {
                        newInputName = value;
                    } else if (index == 1) {
                        newInputName += '[' + (counter - 1) + ']';
                    } else {
                        newInputName += '[' + value + ']';
                    }
                });
            }
            /*var datepickerBirthdayArray = {
                firstDay: 1,
                showOn: 'both',
                dateFormat: 'dd.mm.yy',
                showOtherMonths: true,
                minDate: '-100Y',
                maxDate: '0',
                yearRange: rangeOfBirthDate,
                buttonImage: '/local/templates/saveprolife/build/images/icon-date.svg',
                buttonImageOnly: true,
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
                dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
                dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                changeMonth: true,
                changeYear: true
            };
            $('[datepicker-birthday]').each(function () {
                if ($(this).attr('data-buyer') == 'true') {
                    datepickerBirthdayArray.yearRange = rangeOfBirthDateBuyer;
                    datepickerBirthdayArray.maxDate = '-18Y';
                } else {
                    datepickerBirthdayArray.yearRange = rangeOfBirthDate;
                    datepickerBirthdayArray.maxDate = '0';
                }
                $(this).mask('99.99.9999').datepicker(datepickerBirthdayArray);
            });*/
            // input.attr('id', newInputId);
            input.attr('name', newInputName);
            // input.next($('label')).attr('for', newInputId);
        });
    });

    if (policy == 'ERV') {
        let i = 1;
        $('[data-radio-tourists]').find($('[js-radio-tourist]')).each(function () {
            let radio = $(this);
            radio.attr('data-id-tourist', i);
            let idRadio = 'buyer_' + i;
            radio.find($('[type=radio]')).attr('id', idRadio).attr('data-radio-value', i);
            radio.find($('label')).attr('for', idRadio).text('Турист ' + i);
            i++;
        });
        // если был удалён выбранный в radio турист, устанавливаем выбор на "Турист 1"
        if (!$('[name="buyer_radio_tourists"][checked]').length) {
            $('[for=buyer_1]').trigger('click');
        }
    }

    $('[js-add-additional-tourist]').attr('data-count-of-tourists', counter);
    if (counter < 6 && $('[js-add-additional-tourist]').is(':hidden')) $('[js-add-additional-tourist]').show();
    if (counter == 1) $('[js-delete-tourist]').hide();
}

// Функция автопосдтавления возрасту склонения год/года/лет
function changePerson(person) {
    let countAge = $(person).val();

    let btnName = $('[data-count-tourist]').text().trim();
    if (btnName === "Возраст") {
        $('[data-count-tourist]').text('1 турист');
    }
    countAge = $(person).val().split(/[\D]+/g)[0];
    if (countAge > 99){
        countAge = 99;
    }
    if (countAge != ''){
        let valCount = num2str(countAge, ['год', 'года', 'лет']);
        $(person).val(countAge + ' ' + valCount);
        $(person).attr('maxlength', countAge.length + 1 + valCount.length)
    }
    else {
        $(person).val('');
        $(person).attr('maxlength','2');
    }
}

$( '[data-age-insured]' ).each(function( index ) {
    if($(this).val().length > 2){
        let length = $(this).val().length;
        $(this).attr('maxlength', length);
    }
});


function searchNumber(personNumber){
    if ($(personNumber).val().length > 2){
        let countNumber = parseInt($(personNumber).val().replace(/[^\d]/g, '').length);
        let stringNumber = $(personNumber).val().slice( 0 , countNumber );
        $(personNumber).val(stringNumber);
    }
    $(personNumber).attr('maxlength','2');
}

// Функция обновления числа количества туристов
function updateCountTourist(count) {
    let imgElementgs = '<img src="/local/templates/saveprolife/build/images/down-bold.svg" alt="down_bold_img" class="down">\n' +
                       '<img src="/local/templates/saveprolife/build/images/up-bold.svg" alt="up_bold_img" class="up">';
    let val = num2str(count, ['турист', 'туриста', 'туристов']);
    $('[data-count-tourist]').text(count + ' ' + val);
    $('[data-count-tourist]').append(imgElementgs);
}

/*
 Функция определения склонения чисел
 */
function num2str(n, text_forms) {
    n = Math.abs(n) % 100; var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

/*
* Обработка кликов на выпадающие списки
* (чтобы при клики на список - не скрывался сам список)
*  */
$('.dropdown-menu').on('click', function (event) {
    // Делаем исключение для выпадающего списка период поездки в днях
    if($(this).is('[data-period-calendar]')) {
        return true;
    }
    return false;
});
// Если нажатие на enter, то сбрасываем событие
$('.dropdown-menu').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
        e.preventDefault();
        return false;
    }
});

/*
* ----------- Полис ЕРВ
* Появление формы с дополнительными полями, если выбрано "Другое лицо"
*/
$(function () {
    selectedTouristBlock('1');
});

// Передача данных из input'ов с туристом в input'ы с покупателем
// - если выбран один из туристов в radio
// - если поле с выбранным туристом изменилось
$('#ERVForm').on('keyup focusout', 'input:not(.hasDatepicker)', function () {
    const idTourist = $('[data-all-tourists]').attr('data-selected-tourist');
    const idBlock = $(this).closest($('[data-tourist]')).attr('data-tourist-block-id');
    if (idTourist == idBlock) {
        fromTouristToBuyer ($(this));
    }
});

function radioTouristsChange (element) {
    radioChangeFunction (element);
    let idTourist = element.attr('data-radio-value');
    if (idTourist == 'default') {
        $('[data-buyer-default]').find($('input')).each(function () {
            $(this).removeAttr('value').val('');
            $(this).parent().find($('.flying_label__label')).removeClass('label_up');
        });
        $('[data-buyer-default]').show();
    } else {
        $('[data-buyer-default]').hide();
        $('[data-tourist-block-id=' + idTourist + ']').find($('input')).each(function () {
            fromTouristToBuyer ($(this));
        });
    }
    selectedTouristBlock(idTourist);
}

// Смена туриста в результате смены значения radio
function selectedTouristBlock (id) {
    $('[data-all-tourists]').attr('data-selected-tourist', id);
}

// Перенос значения input'а туриста в input покупателя
function fromTouristToBuyer (element) {
    const id = element.attr('id');
    const target = 'tourist_';
    if (id.indexOf(target) == 0) {
        let value = element.val();
        let newID = '#buyer_' + id.substring(target.length, id.length - 2);
        $(newID).val(value);
    }
}

function datepickerBirthdayChange (element) {
    const idTourist = $('[data-all-tourists]').attr('data-selected-tourist');
    const idBlock = element.closest($('[data-tourist]')).attr('data-tourist-block-id');
    if (idTourist == idBlock) {
        const value = element.val();
        const id = element.attr('id');
        const target = 'tourist_';
        if (id.indexOf(target) == 0) {
            let newID = '#buyer_' + id.substring(target.length, id.length - 2);
            $(newID).val(value);
        }
    }
}

//=================== Перепрыгивание с даты на дату =======================
$(document).on('change', '[name=mainFormDateFrom]', function(){
    $('[name=mainFormDateFrom]').next().attr('src','/local/templates/saveprolife/build/images/icon-date.svg');
    $('[name=mainFormDateFrom]').removeClass('error');
    setTimeout(() => $("[name=mainFormDateTo]").datepicker("show"), 1);
    setTimeout(() => $('[name=mainFormDateTo]').next().attr('src','/local/templates/saveprolife/build/images/icon-date-active.svg'), 1);
});
$(document).on('change', '[name=mainFormDateTo]', function(){
    $('[name=mainFormDateTo]').next().attr('src','/local/templates/saveprolife/build/images/icon-date.svg');
    $('[name=mainFormDateTo]').removeClass('error');
});
$(document).on('change', '[name=mainFormDateFromYear]', function(){
    $('[name=mainFormDateFromYear]').next().attr('src','/local/templates/saveprolife/build/images/icon-date.svg');
    $(".dropdown_value").trigger('click');
});

//================= Ограничение ввода в 5000 в поле отмена поездки ==================
function handleChangeTripCancel(input){
    if (input.value < 0) input.value = 0;
    if (input.value > 5000) input.value = 5000;
}



// ------------------- МЕНЮ -------------------
// Добавление белого фона доп.меню при прокрутке страницы (на главной, 404 и "Партнёрам")
$(function () {
    function positionPageTop () {
        if ($(window).scrollTop() > 1) {
            let baseElementStyle = {
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid rgba(43, 51, 65, 0.15)",
                boxShadow: "0 2px 5px rgba(0, 40, 183, 0.2)"
            };
            $('.header__hide_container__main').css(baseElementStyle);
            let allLinksElementStyle = {
                color: "#293365"
            };
            $('.header__top_nav__main').children($('li')).children($('a')).each(function () {
                $(this).css(allLinksElementStyle);
            });
        } else {
            $('.header__hide_container__main').removeAttr('style');
            $('.header__top_nav__main').children($('li')).children($('a')).each(function () {
                $(this).removeAttr('style');
            });
        }
    }

    if ($(window).width() > 992) {
        if ($('.header__base__main').length > 0) {
            if ($(window).scrollTop() > 100) {
                positionPageTop ()
            }
            $(window).scroll(function() {
                positionPageTop ()
            });
        }
    }
});

// Раскрытие доп.меню по нажатию на "|||"
$('[data-open-dop-menu]').on('click', function () {
    let typeMenu = $(this).attr('data-type');
    $('.header__hide_container' + typeMenu).show();
    $(this).hide();
    $('[data-close-dop-menu]').css('display', 'flex');

    let widthScreen = $(window).width();
    if (widthScreen <= 992) {
        $('html').addClass('no_scroll');
        $('body').addClass('no_scroll');
    }
});
// Скрытие доп.меню по нажатию на "X"
$('[data-close-dop-menu]').on('click', function () {
    let typeMenu = $(this).attr('data-type');
    $('.header__hide_container' + typeMenu).hide();
    $(this).hide();
    $('[data-open-dop-menu]').css('display', 'flex');

    let widthScreen = $(window).width();
    if (widthScreen <= 992) {
        $('html').removeClass('no_scroll');
        $('body').removeClass('no_scroll');
    }
});
// Скрытие доп.меню по нажатию на любую из ссылок меню
$('.header__base__default').find($('[data-top-menu-child]')).on('click', 'a', function () {
    $('.header__hide_container__default').hide();
    $('.header__hide_container__main').hide();
    $('[data-close-dop-menu]').hide();
    $('[data-open-dop-menu]').show();

    let widthScreen = $(window).width();
    if (widthScreen <= 992) {
        $('html').removeClass('no_scroll');
        $('body').removeClass('no_scroll');
    }
});

// Открытие/Закрытие поисковой строки по нажатию на лупу
$('[data-open-search-menu]').on('click', function () {
    $('.header__search').addClass('show');
});
$('[data-close-search-menu]').on('click', function () {
    $('.header__search').removeClass('show');
});

// Корректировка отображения доп.меню при наведение на один из его пунктов
/*$(function () {
    var timer;

    $('.header__top_nav__main').children($('li')).hover(
        function() {
            let widthScreen = $(window).width();
            let menuItem = $(this);
            if (widthScreen > 992) {
                timer = setInterval(function() {
                    let baseElementStyle = {
                        backgroundColor: "#FFFFFF",
                        borderTop: "1px solid rgba(43, 51, 65, 0.15)",
                        boxShadow: "0 2px 5px rgba(0, 40, 183, 0.2)"
                    };
                    $('.header__hide_container__main').css(baseElementStyle);

                    let allLinksElementStyle = {
                        color: "#293365"
                    };
                    $('.header__top_nav__main').children($('li')).children($('a')).each(function () {
                        $(this).css(allLinksElementStyle);
                    });

                    let selectedLinkElementStyle = {
                        color: "#FF4069"
                    };
                    menuItem.children($('a')).css(selectedLinkElementStyle);

                    let hideDivElementStyle = {
                        transform: "translateX(0)"
                    };
                    menuItem.children($('.header__hide_container__dropdown')).css(hideDivElementStyle);
                }, 150);
            }
        },
        function() {
            let menuItem = $(this);
            if (timer) {*/
                /*$('.header__top_nav__main').hover(
                    function () {

                    },
                    function () {

                    }
                );*/
                /*setTimeout(function() {
                    clearInterval(timer);
                    menuItem.children($('.header__hide_container__dropdown')).removeAttr('style');
                    if ($(window).scrollTop() < 100) {
                        $('.header__hide_container__main').removeAttr('style');
                        $('.header__top_nav__main').children($('li')).children($('a')).each(function () {
                            $(this).removeAttr('style');
                        });
                        menuItem.children($('a')).removeAttr('style');
                    } else {
                        menuItem.children($('a')).css("color", "#293365");
                    }
                }, 150);
            }
        }
    );
});*/
// для адаптива
$('.header__top_nav__main').children($('li')).on('click', function () {
    let widthScreen = $(window).width();
    if (widthScreen <= 992) {
        let menuItem = $(this);
        if ($(this).attr('data-clicked') == 'true') {
            $(this).removeAttr('data-clicked');
            menuItem.find($('div.header__hide_container__dropdown')).removeAttr('style');
        } else {
            $('.header__top_nav__main').children($('li')).each(function () {
                $(this).removeAttr('data-clicked');
                $(this).find($('div.header__hide_container__dropdown')).removeAttr('style');
            });
            $(this).attr('data-clicked', 'true');
            let hideDivElementStyle = {
                display: "flex"
            };
            menuItem.find($('div.header__hide_container__dropdown')).css(hideDivElementStyle);
        }
    }
});

// Корректировка отображения подпунктов доп.меню
$(function () {
    $('[data-top-menu-child]').each(function () {
        let idValue = $(this).attr('data-child-id');
        $('[data-parent-id=' + idValue + ']').addClass('parent');
    });
    $('[data-top-menu-child]').each(function () {
        let element = $(this).find(['data-top-menu-child']);
        if (element.attr('data-child-id') !== $(this).attr('data-parent-id')) {
            $(this).removeClass('parent');
        }
    });
});

/* ============================== ГЛАВНАЯ СТРАНИЦА */
/* Переключатель 'Туризм' - 'Спорт/Экстрим' */
/* При нажатии на 'Спорт/Экстрим' */
$('.radio_right_button').on({
    'click': function() {
        $('[data-for-sport-extreme]').show();
        $('.radio_right_icon').show();
        $('.radio_left_icon').hide();
        $('.radio_right_button').removeClass('disabled');
        $('.radio_left_button').addClass('disabled');
        $('[name="checkboxIsTripOrSport"]').attr('value', 1);
    }
});

/* При нажатии на 'Туризм' */
$('.radio_left_button').on({
    'click': function() {
        $('[data-for-sport-extreme]').hide();
        $('.radio_left_icon').show();
        $('.radio_right_icon').hide();
        $('.radio_left_button').removeClass('disabled');
        $('.radio_right_button').addClass('disabled');
        $('[name="checkboxIsTripOrSport"]').attr('value', 0);
    }
});

//-------------Валидация полей в форме-------------------

$(function () {
    $.validator.addMethod('LatinRegExp', function(value, element) {
        return value.match(new RegExp("^$|^" + "[a-zA-Zа-яА-ЯёЁ-]"));
    }, 'Разрешён ввод только латиницей');
    $.validator.addMethod('KirillRegExp', function(value, element) {
        return value.match(new RegExp("^$|^" + "[а-яА-ЯёЁ-]"));
    }, 'Разрешён ввод только кириллицей');
    $.validator.addMethod('EmailRegExp', function(value, element) {
        return value.match(new RegExp("^" + "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\[.]{1}[a-z]{2,}" + "$"));
    }, 'Формат ввода email:"XXXXX@XXXX.XX"');
    $.validator.addMethod('EmailRegExpNoReq', function(value, element) {
        return value.match(new RegExp("^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\[.]{1}[a-z]{2,}$"));
    }, 'Формат ввода email:"XXXXX@XXXX.XX"');
    $.validator.addMethod('NameRegExp', function(value, element) {
        return value.match(new RegExp("^" + "[a-zA-Zа-яА-ЯёЁ-]"));
    }, 'Имя должно состоять из букв');
    $.validator.addMethod('interpassVal', function(value, element) {
        return value.match(new RegExp("^$|^" + "[a-zA-Z0-9-]"));
    }, 'Неверный формат загранпаспорта');

    $("#promocode_form, #save-calc").validate({
        rules:{
            email: {
                required: {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                EmailRegExp: true,
            },
            name: {
                required: true,
                NameRegExp: true,
            }
        },
        messages:{
            email:{
                required: "",
                EmailRegExp: "",
            },
            name: {
                NameRegExp: "",
                required: "",
            }
        }
    });
    $("#reviews_form,#summary_form").validate({
        rules:{
            name:{
                NameRegExp: true,
                required: true,
            },
            email: {
                required: {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                EmailRegExp: true,
            },
            city:{
                required:true,
            },
            wish_career:{
                required:true,
            },
            career:{
                required: true,
            },
            file:{
                required: true,
            },
            letter:{
                required: true,
            },
            comment:{
                required: true,
            },
            phone:{
                required: true,
            }
        },
        messages:{
            email:{
                required: "",
            },
            name:{
                required: "",
            },
            city:{
                required: "",
            },
            wish_career:{
                required: "",
            },
            career:{
                required: "",
            },
            file:{
                required: "",
            },
            letter:{
                required: "",
            },
            comment:{
                required: "",
            },
            phone:{
                required: "",
            }
        }
    });
    $("#comment_form,#feedback_form,#login_form,#reg_form").validate({
        rules:{
            'REGISTER[EMAIL]':{
                required: {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                EmailRegExpNoReq: true,
            },
            'REGISTER[NAME]':{
                required: true,
            },
            'REGISTER[PASSWORD]':{
                required: true,
                minlength: 6,
            },
            'USER_LOGIN':{
                required: true,
                EmailRegExpNoReq: true,
            },
            'USER_PASSWORD':{
                required: true,
                minlength: 6,
            },
            name:{
                required: true,
                NameRegExp: true,

            },
            email: {
                required: false,
                EmailRegExpNoReq: true,
            },
            letter:{
                required: true,
            },
            message:{
                required: true,
            },
            comment:{
                required: true,
            }
        },
        messages:{
            'REGISTER[EMAIL]':{
                required: "",
                EmailRegExpNoReq: "Неверный формат Email",
            },
            'REGISTER[NAME]':{
                required: "",
            },
            'REGISTER[PASSWORD]':{
                required: '',
                minlength: "",
            },
            'USER_LOGIN':{
                required: "",
                EmailRegExpNoReq: "",
            },
            'USER_PASSWORD':{
                required: '',
                minlength: '',
            },
            name:{
                required: "",
            },
            email: {
                EmailRegExpNoReq: "",
            },
            letter:{
                required: "",
            },
            message:{
                required: "",
            },
            comment:{
                required: "",
            }
        }
    });
    $("#corp-client-form,#main-calc-form,#FormFilter").validate({
        rules:{
            companyName:{
                required:true,
            },
            email: {
                required: {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                EmailRegExp: true,
            },
            phone:{
                required:true,
            },
            country:{
                required:true,
            },
            dateFrom:{
                required:true,
            },
            dateTo:{
                required:true,
            },
            mainFormDateFrom:{
                required:true,
            },
            mainFormDateTo:{
                required:true,
            },
            mainFormDateFromYear:{
                required:true,
            },
            "mainFormYearsOld[]":{
                required:true,
            },
            place:{
                required:true,
            },
            insuredDate:{
                required:true,
            },
            crossDate:{
                required:true,
            },
            room:{
                required:true,
            },
            letter:{
                required: true,
            },
            applicantFirstName:{
                required: true,
                NameRegExp: true,

            },
            applicantSecondName:{
                required: true,
                NameRegExp: true,
            },
            applicantInsured:{
                required: true,
                NameRegExp: true,
            },
            insuredPhone:{
                required: true,
            },
            insuredPhoneCountry:{
                required: true,
            },
            applicantPhone:{
                required: true,
            }
        },
        messages:{
            mainFormDateFrom:{
                required: "",
            },
            mainFormDateTo:{
                required: "",
            },
            companyName:{
                required: "",
            },
            email:{
                required: "",
            },
            phone:{
                required: "",
            },
            country:{
                required: "",
            },
            dateFrom:{
                required: "",
            },
            dateTo:{
                required: "",
            },
            mainFormDateFromYear:{
                required: "",
            },
            "mainFormYearsOld[]":{
                required: "",
            },
            place:{
                required: "",
            },
            insuredDate:{
                required: "",
            },
            crossDate:{
                required: "",
            },
            room:{
                required: "",
            },
            letter:{
                required: "",
            },
            applicantFirstName:{
                required: "",
            },
            applicantSecondName:{
                required: "",
            },
            applicantInsured:{
                required: "",
            },
            insuredPhone:{
                required: "",
            },
            insuredPhoneCountry:{
                required: "",
            },
            applicantPhone:{
                required: "",
            },
        }
    });

    //Валидация страницы оформление полисов
    $("#AlfaInsuranceForm,#LibertyForm,#AbsolutForm,#IngosstrahForm").validate({
        rules:{
            'buyer[lastName]':{
                required:true,
                KirillRegExp:true,
            },
            'buyer[name]':{
                required:true,
                KirillRegExp:true,
            },
            'buyer[patronymic]':{
                required:false,
            },
            'buyer[birthday]':{
                required:true,
            },
            'buyer[phone]':{
                required:true,
            },
            'buyer[email]':{
                required: {
                    depends:function(){
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                EmailRegExp: true,
            },
            'buyer[interpass]':{
                required:true,
                maxlength:30,
                interpass:true,
            },
            'buyer[doc][series]':{
                required:true,
                minlength:4,
            },
            'buyer[doc][number]':{
                required:true,
                minlength:6,
            },
            'buyer[doc][date]':{
                required:true,
            },
            'buyer[doc][city]':{
                required:true,
            },
            'tourists[0][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[1][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[2][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[3][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[4][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[0][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[1][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[2][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[3][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[4][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[0][birthday]':{
                required: true
            },
            'tourists[1][birthday]':{
                required: true
            },
            'tourists[2][birthday]':{
                required: true
            },
            'tourists[3][birthday]':{
                required: true
            },
            'tourists[4][birthday]':{
                required: true
            },
        },
        messages:{
            'buyer[lastName]':{
                required:'',
            },
            'buyer[name]':{
                required:'',
            },
            'buyer[patronymic]':{
                required:'',
            },
            'buyer[birthday]':{
                required:'',
            },
            'buyer[phone]':{
                required:'',
            },
            'buyer[email]':{
                required:'',
            },
            'buyer[interpass]':{
                required:'',
                // maxlength:'Номер загранпаспорта составляет 7 цифр',
            },
            'buyer[doc][series]':{
                required:'',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'buyer[doc][number]':{
                required:'',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'buyer[doc][date]':{
                required:'',
            },
            'buyer[doc][city]':{
                required:'',
            },
            'tourists[0][lastName]':{
                required:'',
            },
            'tourists[1][lastName]':{
                required:'',
            },
            'tourists[2][lastName]':{
                required:'',
            },
            'tourists[3][lastName]':{
                required:'',
            },
            'tourists[4][lastName]':{
                required:'',
            },
            'tourists[0][name]':{
                required:'',
            },
            'tourists[1][name]':{
                required:'',
            },
            'tourists[2][name]':{
                required:'',
            },
            'tourists[3][name]':{
                required:'',
            },
            'tourists[4][name]':{
                required:'',
            },
            'tourists[0][birthday]':{
                required:'',
            },
            'tourists[1][birthday]':{
                required:'',
            },
            'tourists[2][birthday]':{
                required:'',
            },
            'tourists[3][birthday]':{
                required:'',
            },
            'tourists[4][birthday]':{
                required:'',
            },
        }
    });
    $("#ERVForm").validate({
        rules: {
            'tourists[0][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[1][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[2][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[3][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[4][lastName]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[0][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[1][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[2][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[3][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[4][name]':{
                required: true,
                LatinRegExp:true,
            },
            'tourists[0][birthday]':{
                required: true
            },
            'tourists[1][birthday]':{
                required: true
            },
            'tourists[2][birthday]':{
                required: true
            },
            'tourists[3][birthday]':{
                required: true
            },
            'tourists[4][birthday]':{
                required: true
            },
            'tourists[0][interpass]':{
                required: true,
                maxlength: 30,
                interpassVal: true,
            },
            'tourists[1][interpass]':{
                required: true,
                maxlength: 30,
                interpassVal: true,
            },
            'tourists[2][interpass]':{
                required: true,
                maxlength: 30,
                interpassVal: true,
            },
            'tourists[3][interpass]':{
                required: true,
                maxlength: 30,
                interpassVal: true,
            },
            'tourists[4][interpass]':{
                required: true,
                maxlength: 30,
                interpassVal: true,
            },
            'tourists[0][city]':{
                required: true
            },
            'tourists[1][city]':{
                required: true
            },
            'tourists[2][city]':{
                required: true
            },
            'tourists[3][city]':{
                required: true
            },
            'tourists[4][city]':{
                required: true
            },
            'tourists[0][address]':{
                required: true,
                LatinRegExp: true,
            },
            'tourists[1][address]':{
                required: true,
                LatinRegExp: true,
            },
            'tourists[2][address]':{
                required: true,
                LatinRegExp: true,
            },
            'tourists[3][address]':{
                required: true,
                LatinRegExp: true,
            },
            'tourists[4][address]':{
                required: true,
                LatinRegExp: true,
            },
            'tourists[0][doc][lastName]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[1][doc][lastName]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[2][doc][lastName]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[3][doc][lastName]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[4][doc][lastName]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[0][doc][name]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[1][doc][name]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[2][doc][name]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[3][doc][name]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[4][doc][name]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[0][doc][patronymic]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[1][doc][patronymic]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[2][doc][patronymic]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[3][doc][patronymic]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[4][doc][patronymic]':{
                required: true,
                KirillRegExp: true,
            },
            'tourists[0][doc][series]':{
                required: true,
                minlength: 4,
            },
            'tourists[1][doc][series]':{
                required: true,
                minlength: 4,
            },
            'tourists[2][doc][series]':{
                required: true,
                minlength: 4,
            },
            'tourists[3][doc][series]':{
                required: true,
                minlength: 4,
            },
            'tourists[4][doc][series]':{
                required: true,
                minlength: 4,
            },
            'tourists[0][doc][number]':{
                required: true,
                minlength: 6,
            },
            'tourists[1][doc][number]':{
                required: true,
                minlength: 6,
            },
            'tourists[2][doc][number]':{
                required: true,
                minlength: 6,
            },
            'tourists[3][doc][number]':{
                required: true,
                minlength: 6,
            },
            'tourists[4][doc][number]':{
                required: true,
                minlength: 6,
            },
            'tourists[0][doc][date]':{
                required: true
            },
            'tourists[1][doc][date]':{
                required: true
            },
            'tourists[2][doc][date]':{
                required: true
            },
            'tourists[3][doc][date]':{
                required: true
            },
            'tourists[4][doc][date]':{
                required: true
            },
            'tourists[0][doc][when_issued]':{
                required: true
            },
            'tourists[1][doc][when_issued]':{
                required: true
            },
            'tourists[2][doc][when_issued]':{
                required: true
            },
            'tourists[3][doc][when_issued]':{
                required: true
            },
            'tourists[4][doc][when_issued]':{
                required: true
            },
            'tourists[0][doc][department_code]':{
                required: true
            },
            'tourists[1][doc][department_code]':{
                required: true
            },
            'tourists[2][doc][department_code]':{
                required: true
            },
            'tourists[3][doc][department_code]':{
                required: true
            },
            'tourists[4][doc][department_code]':{
                required: true
            },
            'buyer[phone]':{
                required: true
            },
            'buyer[email]':{
                required: {
                    depends: function () {
                        $(this).val($.trim($(this).val()));
                        return true;
                    }
                },
                EmailRegExp: true,
            },
            'buyer[lastName]':{
                required: true,
                LatinRegExp: true,
            },
            'buyer[name]':{
                required: true,
                LatinRegExp: true,
            },
            'buyer[birthday]':{
                required: true,
            },
            'buyer[interpass]':{
                interpassVal: true,
                required: true,
                maxlength: 30
            },
            'buyer[city]':{
                required: true
            },
            'buyer[address]':{
                required: true
            },
            'buyer[doc][lastName]':{
                required: true,
                KirillRegExp: true,
            },
            'buyer[doc][name]':{
                required: true,
                KirillRegExp: true,
            },
            'buyer[doc][patronymic]':{
                required: false,
                KirillRegExp: true,
            },
            'buyer[doc][series]':{
                required: true,
                minlength: 4,
            },
            'buyer[doc][number]':{
                required: true,
                minlength: 6,
            },
            'buyer[doc][date]':{
                required: true,
            },
            'buyer[doc][when_issued]':{
                required: true,
            },
            'buyer[doc][department_code]':{
                required: true,
            }
        },
        messages:{
            'tourists[0][lastName]':{
                required: ''
            },
            'tourists[1][lastName]':{
                required: ''
            },
            'tourists[2][lastName]':{
                required: ''
            },
            'tourists[3][lastName]':{
                required: ''
            },
            'tourists[4][lastName]':{
                required: ''
            },
            'tourists[0][name]':{
                required: ''
            },
            'tourists[1][name]':{
                required: ''
            },
            'tourists[2][name]':{
                required: ''
            },
            'tourists[3][name]':{
                required: ''
            },
            'tourists[4][name]':{
                required: ''
            },
            'tourists[0][birthday]':{
                required: ''
            },
            'tourists[1][birthday]':{
                required: ''
            },
            'tourists[2][birthday]':{
                required: ''
            },
            'tourists[3][birthday]':{
                required: ''
            },
            'tourists[4][birthday]':{
                required: ''
            },
            'tourists[0][interpass]':{
                required: ''
            },
            'tourists[1][interpass]':{
                required: ''
            },
            'tourists[2][interpass]':{
                required: ''
            },
            'tourists[3][interpass]':{
                required: ''
            },
            'tourists[4][interpass]':{
                required: ''
            },
            'tourists[0][city]':{
                required: ''
            },
            'tourists[1][city]':{
                required: ''
            },
            'tourists[2][city]':{
                required: ''
            },
            'tourists[3][city]':{
                required: ''
            },
            'tourists[4][city]':{
                required: ''
            },
            'tourists[0][address]':{
                required: ''
            },
            'tourists[1][address]':{
                required: ''
            },
            'tourists[2][address]':{
                required: ''
            },
            'tourists[3][address]':{
                required: ''
            },
            'tourists[4][address]':{
                required: ''
            },
            'tourists[0][doc][lastName]':{
                required: ''
            },
            'tourists[1][doc][lastName]':{
                required: ''
            },
            'tourists[2][doc][lastName]':{
                required: ''
            },
            'tourists[3][doc][lastName]':{
                required: ''
            },
            'tourists[4][doc][lastName]':{
                required: ''
            },
            'tourists[0][doc][name]':{
                required: ''
            },
            'tourists[1][doc][name]':{
                required: ''
            },
            'tourists[2][doc][name]':{
                required: ''
            },
            'tourists[3][doc][name]':{
                required: ''
            },
            'tourists[4][doc][name]':{
                required: ''
            },
            'tourists[0][doc][patronymic]':{
                required: ''
            },
            'tourists[1][doc][patronymic]':{
                required: ''
            },
            'tourists[2][doc][patronymic]':{
                required: ''
            },
            'tourists[3][doc][patronymic]':{
                required: ''
            },
            'tourists[4][doc][patronymic]':{
                required: ''
            },
            'tourists[0][doc][series]':{
                required: '',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'tourists[1][doc][series]':{
                required: '',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'tourists[2][doc][series]':{
                required: '',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'tourists[3][doc][series]':{
                required: '',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'tourists[4][doc][series]':{
                required: '',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'tourists[0][doc][number]':{
                required: '',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'tourists[1][doc][number]':{
                required: '',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'tourists[2][doc][number]':{
                required: '',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'tourists[3][doc][number]':{
                required: '',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'tourists[4][doc][number]':{
                required: '',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'tourists[0][doc][date]':{
                required: ''
            },
            'tourists[1][doc][date]':{
                required: ''
            },
            'tourists[2][doc][date]':{
                required: ''
            },
            'tourists[3][doc][date]':{
                required: ''
            },
            'tourists[4][doc][date]':{
                required: ''
            },
            'tourists[0][doc][when_issued]':{
                required: ''
            },
            'tourists[1][doc][when_issued]':{
                required: ''
            },
            'tourists[2][doc][when_issued]':{
                required: ''
            },
            'tourists[3][doc][when_issued]':{
                required: ''
            },
            'tourists[4][doc][when_issued]':{
                required: ''
            },
            'tourists[0][doc][department_code]':{
                required: ''
            },
            'tourists[1][doc][department_code]':{
                required: ''
            },
            'tourists[2][doc][department_code]':{
                required: ''
            },
            'tourists[3][doc][department_code]':{
                required: ''
            },
            'tourists[4][doc][department_code]':{
                required: ''
            },
            'buyer[phone]':{
                required: ''
            },
            'buyer[email]':{
                required: '',
                EmailRegExp: "Неверный формат Email",
            },
            'buyer[lastName]':{
                required: ''
            },
            'buyer[name]':{
                required: ''
            },
            'buyer[birthday]':{
                required: ''
            },
            'buyer[interpass]':{
                required: ''
            },
            'buyer[city]':{
                required: ''
            },
            'buyer[address]':{
                required: ''
            },
            'buyer[doc][lastName]':{
                required: ''
            },
            'buyer[doc][name]':{
                required: ''
            },
            'buyer[doc][patronymic]':{
                required: false
            },
            'buyer[doc][series]':{
                required: '',
                minlength:'Серия паспорта составляет 4 цифр',
            },
            'buyer[doc][number]':{
                required: '',
                minlength:'Номер паспорта составляет 6 цифр',
            },
            'buyer[doc][date]':{
                required: '',
            },
            'buyer[doc][when_issued]':{
                required: '',
            },
            'buyer[doc][department_code]':{
                required: '',
            }
        }
    });

    // - после загрузки страницы
    $('form').each(function () {
        checkingOfCheckboxes ($(this));
    });
    // - после изменения значений checkbox'ов
    $(document).on('click', '.promo-check', function(){
        checkingOfCheckboxes ($(this).closest($('form')));
    });

    // Проверка на чекбокс в форме
    function checkingOfCheckboxes (form) {
        let countPromoCheck = 0;
        let countPromoCheckChecked = 0;
        let submitDefault = form.find('[data-check-submit]');
        let submitReg = form.find('[data-check-submit-reg]');
        form.find('.checkbox__dark, .checkbox__light, .checkbox__purple').each(function () {
            if ($(this).find($('.promo-check'))) {
                countPromoCheck++;
                let check = $(this).find($('.checkbox__mark')).hasClass('checked');
                if (check) countPromoCheckChecked++;
            }
        });
        if ((countPromoCheck == countPromoCheckChecked) && submitDefault.prop("disabled", true)) {
            submitDefault.prop("disabled", false);
            submitReg.prop("disabled", false);
        }
        else {
            submitDefault.prop("disabled", true);
            submitReg.prop("disabled", true);
        }
    }
});

// Валидация SOS 2 шаг
$(".insurance-case").validate({

    focusInvalid: false,
    invalidHandler: function(form, validator) {

        if (!validator.numberOfInvalids())
            return;

        $('html, body').animate({
            scrollTop: ($(validator.errorList[0].element).offset().top - 100)
        }, 1000);

    },

    rules:{
        placeAndDateAccident:{
            required: true,
        },
        insuredDate:{
            required: true,
        },
        placeOfTheIncident:{
            required: true,
        },
        borderCrossingDate:{
            required: false,
        },
        reasonForTheApplication:{
            required: true,
        },
        insuredPhone:{
            required: true,
        },
        insuredPhoneCountry:{
            required: true,
        },
        applicantSecondName:{
            required: true,
        },
        applicantFirstName:{
            required: true,
        },
        applicantFamilyInsured:{
            required: true,
        },
        applicantPhone:{
            required: true,
        },
    },
    messages:{
        placeAndDateAccident:{
            required: "",
        },
        insuredDate:{
            required: "",
        },
        placeOfTheIncident:{
            required: "",
        },
        borderCrossingDate:{
            required: "",
        },
        reasonForTheApplication:{
            required: "",
        },
        insuredPhone:{
            required: "",
        },
        insuredPhoneCountry:{
            required: "",
        },
        applicantSecondName:{
            required: "",
        },
        applicantFirstName:{
            required: "",
        },
        applicantFamilyInsured:{
            required: "",
        },
        applicantPhone:{
            required: "",
        },
    }
});

// Кнопка "очистить" в полях с поиском
$(function () {
    $('[data-search-input]').parent('div').prepend('<button type="button"></button>');
    $('[data-search-input]').on('input',function(){
        if($(this).val() === ''){
            $(this).prev('button').removeClass('search-clear');
        }
        else{
            $(this).prev('button').addClass('search-clear');
        }
    });
    $(document).on('click', '.search-clear', function(e){
        $(this).next('input').val('');
        $('[data-search-input]').prev('button').removeClass('search-clear');
    });
});

//Запрет ввода даты вручную
$(function () {
    $('[datepicker]').attr('onkeydown', 'return false');
});

//===============Вывод плашки с ошибкой =======================
$(document).on('click','[data-check-submit]',function(){
    $('.error-panel').remove();
    for (let i = 0; i < $('[data-check-submit]').closest('form').find('[data-required]').length; i++) {
        if($(this).closest('form').find('[data-required]').eq(i).val()===''){
            var bolError='true';
        }
        else{
        }
    }
    if(bolError=='true'){
        const error_panel = '<div class="col-12 mb-5 p-2 error-panel"><img src="/local/templates/saveprolife/build/images/icon-attantion-red.svg"><p class="ml-4 error-panel-text">Заполните, пожалуйста, все обязательные поля</p></div>';
        $(this).parent('div').parent('div').prepend(error_panel);
    }
});

//===============Удаление плашки при вводе данных в инпут =======================
$(document).on('input','[data-required]',function(){
    for (let i = 0; i < $('[data-check-submit]').closest('form').find('[data-required]').length; i++) {
        if($('[data-check-submit]').closest('form').find('[data-required]').eq(i).val()!==''){
            $('.error-panel').remove();
        }
    }
});

//Добавил имя для поля со странами
$(function(){
    $('.select2').ready(function () {
        $('.select2-search__field').attr('name','country');
    });
});

//проверка валидации на старнице Корпоративным клиентам и калькуляторе
$(document).on('click','#main-calc-form-submit',function(){
    $(this).closest('form').find('.select2-selection__rendered').addClass('error');
    $(this).closest('form').find('.select2-search__field').addClass('error');

    //если есть старна в строке то чистим все классы с ошибками
    if($(this).closest('form').find('.select2-selection__choice').is('[title]')){
        $(this).removeAttr("name");
        $(this).removeClass('error');
        $(this).closest('form').find('.select2-search__field').removeClass('error');
    }

    //Изменение
    $('.hasDatepicker').each(function () {
        if ($(this).val() == '') {
            $(this).addClass('error');
            $(this).siblings('.ui-datepicker-trigger').attr('src', '/local/templates/saveprolife/build/images/icon-date-error.svg');
        }
    });

    //Добавление класса error для поля страны поездки
    if($(this).closest('form').find('.select2-selection__choice').is('[title]')){
        $(this).closest('form').find('.select2-selection__rendered').removeClass('error');
        $(this).closest('form').find('.select2-search__field').removeAttr('name');
    }
    else{
        $(this).closest('form').find('.select2-search__field').attr('name','country');
    }

    //Валидация поля Возраст туристов
    if($(this).closest('form').find('[name="mainFormYearsOld[]"]').val()==''){
        $('.person').children('button').addClass('error');
        $('.person').children('button').css('background-image', 'url(/local/templates/saveprolife/build/images/person-sign-error.svg)');
    }
    let isFilled = true;
    $("input.YearsOld").each(function () {
        if ($(this).val() == ''){
            $(this).addClass('error');
            $('.dropdown.person').children('[data-count-tourist]').addClass('error');
            isFilled = false
        }
    });
    if (!isFilled){
        event.preventDefault();
    }
});

$(document).on('click','.select2-selection__choice__remove',function(){
    if($(this).closest('form').find('.select2-selection__choice').is('[title]')){

    }
    else{
        $(this).closest('form').find('.select2-selection__rendered').addClass('error');
    }
});
$(document).on('click','.select2-container--default',function(){
    $(this).closest('form').find('.select2-selection__rendered').removeClass('error');
    $(this).closest('form').find('.select2-search__field').removeClass('error');
});

$(document).on('change','[name="mainFormYearsOld[]"]',function(){
    $('.person').children('button').removeClass('error');
    $('.person').children('button').css('background-image', 'url(/local/templates/saveprolife/build/images/person-sign.svg)');
});

$(document).on('click','[data-val]',function(){
    $(this).closest('form').find('.select2-selection__rendered').removeClass('error');
    $(this).closest('form').find('.select2-search__field').removeClass('error');
});

/*$(document).on('click','div[name="mainFormAnnualPolicy"]',function(){
    if($(this).hasClass('checked')){

    }
});*/

$(function () {
    $(document).on('focus', '.buyer_doc_code', function () {
        $(this).mask("999-999");
    });

    function inputPromocode(element) {
        let promocode = $('#inputPromocode').val();
        let price = $('#polisPrice').val();
        let policyInfo = $('#policyInfo').val();
        let buyBtn = $('#buyBtn');
        let textPrice = $('.selected-polis__amount');
        let promocodeBox = $('.selected-polis__promocod-box');
        let promocodeInput = $('.selected-polis__promocod-box').find($('[type=text]'));
        let promocodeButton = $('[data-activate-promocode]');
        buyBtn.prop('disabled', true);
        element.prop('disabled', true);
        $.post('/policy/service/applicationPromocode.php',
            {promocode: promocode, price: price, hiddenData: policyInfo}).done(function (data) {
            console.log(data);
            if (data.isApply === 'true') {
                if (promocodeBox.hasClass('error'))
                    promocodeBox.removeClass('error');
                promocodeBox.addClass('success');
                textPrice.addClass('success');
                if (promocodeButton.attr('disabled') == 'true')
                    promocodeButton.attr('disabled', 'false');
                promocodeButton.attr('readonly', 'true');
                let newPrice = data.newPrice + ' ₽';
                textPrice.html(newPrice);
                buyBtn.html('Оплатить ' + newPrice);
                $('#couponCode').val(promocode);
            } else {
                promocodeBox.addClass('error');
                promocodeButton.attr('disabled', 'true');
                if (textPrice.hasClass('success')) textPrice.removeClass('success');
            }
        }).fail(function (xhr, status, error) {
            console.log('error');
        }).always(function () {
            buyBtn.prop('disabled', false);
            element.prop('disabled', false);
        });
    }

    $('[data-activate-promocode]').on('click', function () {
        inputPromocode($(this));
    });

    $('#inputPromocode').keydown(function (e) {
        if (e.keyCode === 13) {
            inputPromocode($(this));
        }
    });

    $('[data-cross-promocode]').on('click', function () {
        let inputText = $('.selected-polis__promocod-box').find($('[type="text"]'));
        inputText.val(inputText.data('defvalue'));
        $('.selected-polis__promocod-box').removeClass('error')
    });

    $('#buyBtn').on('click', function (e) {
        e.preventDefault();
        let _self = $(this);
        let form = $(this).closest('form');
        let formData = new FormData(form[0]);
        let isValide = form.valid();
        if (isValide) {
            _self.prop('disabled', true);
            $('#spinner').css('display', 'block');
            $.ajax({
                type: "POST",
                url: "/policy/service/getPolicy.php",
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (data, textStatus, jqXHR) {
                    let isRedirect = true;
                    // Блок обновления цены
                    if (typeof data.price !== "undefined" && data.price !== '') {
                        const regEx = /[^\d\+]/g;
                        const clearPrice = data.price.replace(regEx, '');
                        const isRussia = data.isRussia;
                        console.log(isRussia);
                        // Если стоимость полиса 0 рублей, то возвращаем пользователя на страницу программ страхования
                        if (parseInt(clearPrice, 10) === 0) {
                            window.location.href = '/filter';
                            return true;
                        }
                        $('#spinner').css('display', 'none');
                        $('[data-pay-text]').text('Оплатить ' + data.price);
                        $('[js-add-additional-tourist]').attr('data-price-value', clearPrice);
                        _self.prop('disabled', false);
                        $('.selected-polis__amount').text(data.price);
                        $('#polisPrice').val(data.price);
                        $('#isRecalc').val('false');
                        $('#policyInfo').val(data.hiddenData);
                        isRedirect = false;

                        // Добавление/удаление дополнительных блоков в форму, если цена полиса больше/меньше 15000
                        if (clearPrice > 15000) {
                            $('[data-input-activity]').each(function () {
                                $('.flying_label__label').each(function () {
                                   if (!$(this).parent().find($('input')).val()) $(this).removeClass('label_up');
                                });
                                $(this).show();
                            });
                        } else {
                            $('[data-input-activity]').each(function () {
                                $(this).find($('input')).each(function () {
                                    $(this).attr('value', '').val('');
                                });
                                $(this).hide();
                            });
                        }
                        if (!isRussia) {
                            if (clearPrice > 15000) {
                                $('[data-series-number-activity]').each(function () {
                                    $('.flying_label__label').each(function () {
                                        if (!$(this).parent().find($('input')).val()) $(this).removeClass('label_up');
                                    });
                                    $(this).show();
                                });
                            } else {
                                $('[data-series-number-activity]').each(function () {
                                    $(this).find($('input')).each(function () {
                                        $(this).attr('value', '').val('');
                                    });
                                    $(this).hide();
                                });
                            }
                        }
                    }
                    // Блок редиректа на страницу оплаты
                    if (data.status === "success") {
                        redirectPost(data.form.url, data.form.inputs);
                    }
                },
                error: function (data, textStatus, jqXHR) {
                    //process error msg
                },
            });
        }
    });

    function redirectPost(url, inputs) {
        $('<form action="' + url + '" method="post">' + inputs + '</form>').appendTo($(document.body)).submit();
    }

    // При изменений даты у туристов добавляем кнопки значение пересчитать
    $(document).on('change', '[data-bd-insured]', function () {
        let ageTourist = {
            'touristBlockId': (($(this).closest('[data-tourist-block-id]').data('touristBlockId')) - 1),
            'touristAge': $(this).val()
        };
        $.ajax({
            url: "/policy/service/checkAgeTourist.php",
            type: "POST",
            data: ageTourist,
            cache: true,
            dataType: 'json',
            success: function (data) {
                if (data['diffAge']) {
                    $('[data-pay-text]').text('Пересчитать');
                    $('#isRecalc').val('true');
                }
            }
        });

    });

});

let formIsSend = false;
const activeButton = '<input js-password-form-submit onclick="updatePassword()" type="submit" name="save" value="Сохранить личные данные" class="blue_button blue_button__default">';
const preLoadButton = '<button type="button" class="blue_button blue_button__default element_saving">Сохраняем...</button>';
const savedDataButton = '<button type="button" class="blue_button blue_button__default element_saved">Сохранено</button>';
$('form#profile_data').on('submit', function (e) {
    e.preventDefault();
    $('#profile_data').find($('[data-submit-block]')).html(preLoadButton);
    formIsSend = true;
    let data = new FormData($(this)[0]);
    const url = $(this).attr('action');
    data.set('save', 'Сохранить личные данные');
    $.ajax({
        type: "POST",
        url: url,
        data: data,
        processData: false,
        contentType: false,
        success: function(result){
            $('#profile_data').find($('[data-submit-block]')).html(savedDataButton);
            formIsSend = false;
        },
    });
});

// Реализация обработки клика по кнопке Продолжить оформление на странице сравнения и перехода на страницу оформления
$(document).on('click','[data-continue-calc]',function() {
    event.preventDefault();

    const calcCode = $(this).closest('[data-calc-code]').data('calc-code');
    $.get('/local/php_interface/ajax/open_saved_filter.php', {calc: calcCode}, function () {
        window.location.replace('/filter/');
    })
});

$('input[data-error="error-pass"]').keyup(function () {
    if ($('#password_data').find($('[data-password-error]')).is(":visible") && $(this).hasClass('error')) {
        $('#password_data').find($('[data-password-error]')).hide();
    }
    $(this).removeClass('error');
    activatePasswordSubmit();
});

$('input[data-error="miss-match"]').keyup(function () {
    if ($('#password_data').find($('[data-password-error]')).is(":visible") && $(this).hasClass('error')) {
        $('#password_data').find($('[data-password-error]')).hide();
    }
    $('input[data-error="miss-match"]').each(function () {
        $(this).removeClass('error');
    });
    activatePasswordSubmit();
});

function updatePassword () {
    let form = $('form#password_data');
    if ($('#password_data').find($('[data-password-error]')).is(":visible")) $('#password_data').find($('[data-password-error]')).hide();
    $('#password_data').find($('[data-submit-block]')).html(preLoadButton);
    let data = new FormData(form[0]);
    let login = data.get('LOGIN');
    let oldPass = data.get('OLD_PASSWORD');
    let newPass = data.get('NEW_PASSWORD');
    let newPassConfirm = data.get('NEW_PASSWORD_CONFIRM');
    const url = '/cabinet/profile/updatePassword.php';
    $.ajax({
        url: url,
        type: "POST",
        data: { login: login, oldPass: oldPass, newPass: newPass, newPassConfirm: newPassConfirm },
        dataType: "JSON",
        success: function (message) {
            switch (message) {
                case 'error-pass':
                    $('#password_data').find($('[data-submit-block]')).html(activeButton);
                    $('input[data-error="error-pass"]').addClass('error');
                    $('#password_data').find($('[data-password-error]')).css('display', 'flex');
                    $('[data-password-error]').children('p').text('Ошибка: неверный пароль');
                    break;
                case 'miss-match':
                    $('#password_data').find($('[data-submit-block]')).html(activeButton);
                    $('input[data-error="miss-match"]').addClass('error');
                    $('#password_data').find($('[data-password-error]')).css('display', 'flex');
                    $('[data-password-error]').children('p').text('Ошибка: пароли не совпадают');
                    break;
                case 'success':
                    if ($('#password_data').find($('[data-password-error]')).is(":visible")) $('#password_data').find($('[data-submit-block]')).hide();
                    $('#password_data').find($('[data-submit-block]')).html(savedDataButton);
                    break;
            }
        }
    });
}

function activatePasswordSubmit () {
    let countElements = 0;
    let countValidElements = 0;
    $('#password_data').find($('.input_profile')).each(function () {
        countElements++;
        if ($(this).val().length > 5) countValidElements++;
    });
    if (countElements == countValidElements) $('[js-password-form-submit]').removeAttr('disabled');
    else $('[js-password-form-submit]').attr('disabled', 'true');
}

var modalStatusError = '<div id="modal-status-error" class="modal_dialog modal_dialog__message" style="display: block;">\n' +
                '    <div data-modal-close></div>\n' +
                '    <div class="modal_container">\n' +
                '        <div class="notification_block error d-flex align-items-center">Вы уже подписаны на рассылку</div>\n' +
                '    </div>\n' +
                '</div>';

/* Блок с треугольником */
$('[data-dropdown-block]').on('click', function (e) {
    e.preventDefault();
    let id = $(this).attr('data-dropdown-id');
    if ($('.dropdown_block#' + id).hasClass('show')) {
        $('.dropdown_block#' + id).removeClass('show');
        if ($(this).children('.triangle').hasClass('triangle__small__up')) {
            $(this).children('.triangle').removeClass('triangle__small__up');
            $(this).children('.triangle').addClass('triangle__small__down');
        } else if ($(this).children('.triangle').hasClass('triangle__big__up')) {
            $(this).children('.triangle').removeClass('triangle__big__up');
            $(this).children('.triangle').addClass('triangle__big__down');
        }
    }
    else {
        $('.dropdown_block#' + id).addClass('show');
        if ($(this).children('.triangle').hasClass('triangle__small__down')) {
            $(this).children('.triangle').removeClass('triangle__small__down');
            $(this).children('.triangle').addClass('triangle__small__up');
        } else if ($(this).children('.triangle').hasClass('triangle__big__down')) {
            $(this).children('.triangle').removeClass('triangle__big__down');
            $(this).children('.triangle').addClass('triangle__big__up');
        }
    }

    return false;
});

/* Скрытие/открытие карточки с подробной информацией о программе страхования или о страховой компании */
function showDetailedInformation (element) {
    let id = element.attr('id');
    $('.show_detailed_information#' + id).hide();
    $('.hide_detailed_information#' + id).show();
}
function hideDetailedInformation (element) {
    let id = element.attr('id');
    $('.hide_detailed_information#' + id).hide();
    $('.show_detailed_information#' + id).show();
}
function showDetailedInformationAssistance (element) {
    let id = element.attr('data-id');
    $('.show_detailed_information[data-id =' + id + ']').hide();
    $('.hide_detailed_information[data-id =' + id + ']').show();
}
function hideDetailedInformationAssistance (element) {
    let id = element.attr('data-id');
    $('.hide_detailed_information[data-id =' + id + ']').hide();
    $('.show_detailed_information[data-id =' + id + ']').show();
}
$('#calcResult').on('click', '.common_url_button__show', function (e) {
    e.preventDefault();
    let id = $(this).attr('id');
    showDetailedInformation($(this));
    $( ".insurance_card__base_information_program__max_content" ).each(function( index ) {
        $(this).next('.hide_detailed_information').find('.common_url_button__hide').trigger('click');
    });
    $('.insurance_card__base_information_program#' + id).addClass('insurance_card__base_information_program__max_content');
    $('html, body').animate({
        scrollTop:  $('.insurance_card__base_information_program#' + id).closest('.card-wrapper').offset().top - 60 // класс объекта к которому приезжаем
    }, 1000); // Скорость прокрутки

});
$('#calcResult').on('click', '.common_url_button__hide', function (e) {
    e.preventDefault();
    let id = $(this).attr('id');
    hideDetailedInformation($(this));
    $('.insurance_card__base_information_program#' + id).removeClass('insurance_card__base_information_program__max_content');
});
$('.button_detailed_information.show').on('click', function (e) {
    e.preventDefault();
    showDetailedInformationAssistance($(this));
});
$('.button_detailed_information.hide').on('click', function (e) {
    e.preventDefault();
    hideDetailedInformationAssistance($(this));
});


/* --------------------------------- */

/* - SELECT MULTIPLE с поиском - */
$(function () {
    /* Ввод страны по поиску */
    $('[data-dynamic-select-country]').select2({
        placeholder: "Выберите страну",
        maximumSelectionLength: 5,
        language: {
            maximumSelected: function () {
                return "Можно ввести не более 5 записей";
            },
            "noResults": function(){
                return "Нет результатов поиска";            }
        },
        allowClear: true,
        multiple: true,
        width: '100%'
    });

    $('[data-dynamic-search-element-page]').val(null).trigger('change');
    $('[data-dynamic-search-element-page]').select2({
        placeholder: "Введите страну для поиска",
        maximumSelectionLength: 1,
        language: {
            maximumSelected: function () {
                return "Подождите, идёт загрузка...";
            },
            "noResults": function(){
                return "Нет результатов поиска";
            }
        },
        allowClear: true,
        multiple: true,
        width: '100%',
        dropdownCssClass: 'dropdown-search-of-page',
        templateSelection: function (element) {
            var $spinner = $(
                '<div>\n' +
                '  <span data-search-title></span>\n' +
                '  <div class="spinner-border spinner-border-sm" role="status">\n' +
                '    <div class="sr-only">Loading...</div>\n' +
                '  </div>\n' +
                '</div>'
            );
            $spinner.find("span").text(element.text);
            return $spinner;
        }
    });

    $('[data-dynamic-search-element-page]').on('select2:select', function () {
        $('input.select2-search__field').attr('readonly', 'true').css('cursor', 'default');
        let valueName = $(this).val();
        let iblockId = $(this).attr('data-id');
        let iblockCode = $(this).attr('data-code');
        let url = '/' + $(this).attr('data-url') + '/nextPage.php';
        $.ajax({
            url: url,
            type: "POST",
            data: { valueName: valueName, iblockId: iblockId, iblockCode: iblockCode },
            dataType: "JSON",
            success: function (data) {
                if (data) window.location.href = data;
            }
        });
    });

    var select_sport = $('[data-dynamic-select-sport]').select2({
        placeholder: {
            id: "0",
            text: "Выберите вид активного отдыха или спорта"
        },
        maximumSelectionLength: 5,
        language: {
            maximumSelected: function () {
                return "Можно ввести не более 5 записей";
            },
            "noResults": function(){
                return "Нет результатов поиска";
            }
        },
        allowClear: true,
        multiple: true,
        width: '100%'
    });

    $('.filter_options').find($('[data-dynamic-select-sport]')).select2({
        placeholder: {
            id: "0",
            text: "Выбрать вид спорта или отдыха"
        },
        maximumSelectionLength: 5,
        language: {
            maximumSelected: function () {
                return "Можно ввести не более 5 записей";
            },
            "noResults": function(){
                return "Нет результатов поиска";
            }
        },
        allowClear: true,
        multiple: true,
        width: '100%'
    });

    /* Ввод текста из примера */
    $('[data-dynamic-autocomplete] > a').on('click', function () {
        let listName = $(event.target).parent().attr('data-list');

        let listItem = $('[data-dynamic-select-' + listName +']');
        var append = $(this).data('val');
        var current = listItem.val();

        current.push(append);
        listItem.val(current);
        listItem.trigger('change');

    });
});

/* ----------------------------- */

/* --- СРАВНИТЕЛЬНАЯ ТАБЛИЦА ПРОГРАММ СТРАХОВАНИЯ --- */
// при загрузке страницы
$(function () {
    $('.slick-slide').each(function () {
        if ($(this).hasClass('slick-active')) {
            let dataCardNumber = '[data-card-number=' + $(this).attr('data-card-number') + ']';
            $('.table_td' + dataCardNumber).each(function () {
                $(this).addClass('show');
            });
        } else {
            $(this).find($('[type="submit"]')).prop("disabled", true);
        }
    });
});

// после обновления слайдера (при нажатии на одну из стрелок)
$('.slider_compare').on('afterChange', function () {
    $('.slick-slide').each(function () {
        let dataCardNumber = '[data-card-number=' + $(this).attr('data-card-number') + ']';
        if ($(this).attr('aria-hidden') == 'false') {
            $(this).find($('[type="submit"]')).prop("disabled", false);
            $('.table_td' + dataCardNumber).each(function () {
                $(this).addClass('show');
            });
        } else {
            $('.table_td' + dataCardNumber).each(function () {
                $(this).removeClass('show');
            });
            $(this).find($('[type="submit"]')).prop("disabled", true);
        }
    });
});

// обработка клика по кнопке "х"
$(document).on('click','.compared_policy__cross', function() {
    if ($(this).closest($('.slick-slide')).hasClass('slick-active')) {
        const cardNumber = $(this).data('card-number');
        const indexSlide = $(this).closest($('.slick-slide')).attr('data-slick-index');
        // удаление карточки из слайдера
        $('.slider_compare').slick('slickRemove', indexSlide);
        let counter = 0;
        $('.slick-slide').each(function () {
            $(this).attr('data-slick-index', counter);
            counter++;
        });
        // если справа больше нет элементов
        if (!$('.slick-slide.slick-active:last').next().length) {
            // если удалили один из последних элементов слайдера, ТО слайдер следует подвинуть вправо
            const indexCurrentSlide = $('.slick-slide.slick-active:first').prev().attr('data-slick-index');
            $('.slider_compare').slick('slickGoTo', indexCurrentSlide);
        }
        // если слева больше нет элементов, следует убрать кнопку (<)
        if (!$('.slick-slide.slick-active:first').prev().length) {
            $('.compare_turn_left').attr('aria-disabled', true).addClass('slick-disabled');
        }
        // обновление таблицы с оглядкой на слайдер
        $('.table_td').each(function () {
            $(this).removeClass('show');
        });
        $('.slick-slide').each(function () {
            let dataCardNumber = '[data-card-number=' + $(this).attr('data-card-number') + ']';
            if ($(this).attr('aria-hidden') == 'false') {
                $(this).find($('[type="submit"]')).prop("disabled", false);
                $('.table_td' + dataCardNumber).each(function () {
                    $(this).addClass('show');
                });
            } else {
                $(this).find($('[type="submit"]')).prop("disabled", true);
            }
        });
        // удаление карточки из сессии
        $.post('/local/php_interface/ajax/delete_card.php', {chosenCard: cardNumber});
    }
});
/* -------------------------------------------------- */

// Реализация обработки клика по кнопке Купить на странице сравнения и перехода на страницу оформления
$(document).on('click','[data-buy-policy]',function() {
    event.preventDefault();

    // Получаем id кликнутого item
    const itemId = $(this).closest('[data-card-number]').data('card-number');
    getSession().then(function(result) {
        // Получаем массив с карточками
        const cards = result['CHOSEN_COMPARE_CARDS'];

        // Заворачиваем объект текущей карточки в JSON и хэшируем его Base64 для безопасности и избежания проблемы с экранизацией JSON
        const data =  utf8_to_b64(JSON.stringify(cards[itemId]));

        // Делаем редирект с POST-данными data
        redirectWithData('/policy/', 'policyData', data);
    });
});

function getSession() {
    return new Promise((resolve, reject) => {
        $.ajax({ url: '/local/php_interface/ajax/get_session.php', dataType: 'json'}).done(resolve).fail(reject);
    });
}

/*
* Функция кодирования в Base64
*/
function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

/*
* Реализация редиректа с данными (скрытая форма)
*/
var redirectWithData = function(redirectUrl, arg, value) {
    var form = $('<form action="' + redirectUrl + '" method="post">' +
        '<input type="hidden" name="'+ arg +'" value="' + value + '"/>' + '</form>');
    $('body').append(form);
    $(form).submit();
};

/* ОКРАШИВАНИЕ ДАННЫХ = "-" В ТАБЛИЦЕ */
$('.comparison_table').children('.row').children('.col-12').children('.table_td').each(function () {
    if ($(this).text().trim() == '─') $(this).addClass('table_grey_dote');
});
/* ---------------------------------- */

/* НАЖАТИЕ НА КНОПКУ "х" В DROPDOWN */
$(function () {
    $('[data-cross-of-dropdown]').each(function () {
        if ($(this).attr('data-delete') == "true") {
            let defaultValue = $(this).next($('button.dropdown_value')).text().trim();
            $(this).next($('button.dropdown_value')).attr('default-value', defaultValue);
        }
    });
});

/* -- ИЗМЕНЕНИЕ ВАЛЮТЫ В ФИЛЬТРЕ -- */
function changeCurrency (currency_name) {
    let addText = ' ' + currency_symbols[currency_name];
    $('a.with_currency').each(function () {
        let inputValue = '';
        let inputValueCurrent = $(this).text();
        $.each(currency_symbols, function(key, value) {
            inputValue = inputValueCurrent.replace(value, '').trim();
            inputValueCurrent = inputValue;
        });
        let newText = inputValue + addText;
        $(this).text(newText);
    });
    $('.dropdown_select').children('.dropdown-menu').children('a').each(function () {
        let inputValue = '';
        let inputValueCurrent = $(this).text();
        $.each(currency_symbols, function(key, value) {
            inputValue = inputValueCurrent.replace(value, '').trim();
            inputValueCurrent = inputValue;
        });
        let newText = inputValue + addText;
        $(this).text(newText);
    });
    $('[data-dropdown-selecting]').each(function () {
        let inputValue = '';
        let inputValueCurrent = $(this).val();
        $(this).attr('data-currency', currency_name);
        if (inputValueCurrent !== "") {
            $.each(currency_symbols, function(key, value) {
                inputValue = inputValueCurrent.replace(value, '').trim();
                inputValueCurrent = inputValue;
            });
            let newValue = inputValue + addText;
            $(this).val(newValue);
        }
    });
    $('input[type=button].with_currency').each(function () {
        let inputValue = '';
        let inputValueCurrent = $(this).attr('value');
        $.each(currency_symbols, function(key, value) {
            inputValue = inputValueCurrent.replace(value, '').trim();
            inputValueCurrent = inputValue;
        });
        let newText = inputValue + addText;
        $(this).attr('value', newText);
    });
}

// Начальная валюта
$(function () {
    $('[type=radio][name=sumInsuredCurrency]').each(function () {
        if ($(this).attr('checked')) {
            const currencyValue = $(this).attr('value');
            changeCurrency(currencyValue);
            $('[data-dropdown-selecting]').each(function () {
                $(this).attr('data-currency', currencyValue);
            });
        }
    });
});

// Изменение валюты при клике по кнопке с ней
$('[data-currency]').on('click', function () {
    let currency_name = $(this).attr('data-value');
    changeCurrency (currency_name);
});
/* -------------------------------- */

/* -- ДОБАВЛЕНИЕ НОВОГО КОММЕНТАРИЯ -- */
function addNewComment (id, commentId, iblockId, blogId = '') {
    var newComment = '<div class="row">\n' +
        '                            <div class="col-12">\n' +
        '                                <form data-add-comment action="#">\n' +
        '                                    <div class="row">\n' +
        '                                        <div class="col-12">\n' +
        '                                            <input type="hidden" name="iblock_id" value="' + iblockId +'">\n' +
        '                                            <input type="hidden" name="blog_id" value="' + blogId +'">\n' +
        '                                            <input type="hidden" name="comment_id" value="' + id +'">\n' +
        '                                            <textarea class="textarea_review" placeholder="Ваш комментарий" name="comment" id=""></textarea>\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="row">\n' +
        '                                        <div class="col-12 pt-3">\n' +
        '                                            <button type="submit" class="blue_button blue_button__tiny">Отправить</button>\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </form>\n' +
        '                            </div>\n' +
        '                        </div>';
    $('#' + id + '_' + commentId + '.add_comment').append(newComment);
}

$(function () {
    // Отправка формы с комментарием
    let isSendComment = false;
    $('.reviews_container').on('submit', 'form[data-add-comment]', function(e){
        // let idComment = $('[data-add-id=]');
        let form = $(this);
        e.preventDefault();
        console.log($(this).serialize());
        if (!isSendComment){
            isSendComment = true;
            let formId = $(this).attr('data-form-id');
            $.post("/local/php_interface/ajax/add_review.php", $(this).serialize(), function (res){
                const response = JSON.parse(res);
                if (response.status === "success"){
                    $('.add_comment#' + formId).text('');
                    $('[name=comment-' + formId + ']').removeAttr('style');
                    $('#review_modal_notification').show();
                    form.hide();
                    form.parents('.mini-comment').find('button[data-add-comment]').show();
                    form.parents('.review_comment').siblings('.big-comment').find('button[data-add-comment]').show();
                    $('[data-modal-close]').on('click', function () {
                        $('.modal_dialog').hide();
                        $('body').removeClass('modal-open');
                        $('.textarea_review').val('');
                    });
                    setTimeout(function () {
                        $('.modal_dialog').hide();
                        $('body').removeClass('modal-open');
                    }, 4000);
                }
                isSendComment = false;
            });
        }
    });

    // Отправка формы с отзывом
    let isSendReview = false;
    $("form[data-add-review]").submit(function(e){
        e.preventDefault();
        let isValide = $("form[data-add-review]").valid();
        if (isValide) {
            if (!isSendReview) {
                isSendReview = true;
                let form = $(this)[0];
                $.post("/local/php_interface/ajax/add_review.php", $(this).serialize(), function (res) {
                    const response = JSON.parse(res);
                    if (response.status === "success") {
                        form.reset();
                        $('.review_rating').val('false');
                        $('.star_review').each(function () {
                            $(this).removeClass('checked');
                        });
                        $('#review_modal_notification').show();
                        $('[data-modal-close]').on('click', function () {
                            $('.modal_dialog').hide();
                            $('body').removeClass('modal-open');
                        });
                        setTimeout(function () {
                            $('.modal_dialog').hide();
                            $('body').removeClass('modal-open');
                        }, 4000);
                    }
                    isSendReview = false;
                });
            }
        }
    });
});

$('.reviews_container').on('click', '[data-add-comment]', function(e){
    let newId = $(this).attr('name').replace('comment-', '');
    let iblockId = $(this).data('iblock-id');
    let blogId = $(this).data('blog-id');
    let commentId = $(this).data('comment-id');
    let element = $('#' + newId + '.add_comment');
    $(this).hide();
    if ($(this).hasClass('common_url_button')) {
        addNewComment(newId, commentId, iblockId, blogId);
    } else if ($(this).hasClass('blue_text_button')) {
        if (element.children().length) {
            if (element.is(':visible')) {
                element.hide();
            } else if (element.is(':hidden')) {
                element.show();
            }
        } else {
            addNewComment(newId, commentId, iblockId, blogId);
        }
    }
});
/* ----------------------------------- */

/* ОТКРЫТИЕ И ЗАКРЫТИЕ МОДАЛЬНОГО ОКНА */
$('[data-modal-open]').on('click', function () {
    let id = $(this).attr('data-id');
    let styles = {
        display: "block",
        pointerEvents: "auto"
    };
    $('.modal_dialog#' + id).css(styles);
});
$('[data-modal-close], .modal_close').on('click', function () {
    let styles = {
        display: "none",
        pointerEvents: "none"
    };
    $('.modal_dialog').css(styles);
});
/* ------------------------------------ */

/* --- ВЫБОР КОЛИЧЕСТВА ЗВЁЗД - ★★★★★ */
// при наведении
$('.star_review').hover(function () {
    let point = $(this).attr('id').replace('star-', '');
    $('.star_review').each(function () {
        if ($(this).attr('id').replace('star-', '') > point) $(this).toggleClass('not_checked_hover');
        else $(this).toggleClass('checked_hover');
    });
});
// при клике
$('.star_review').on('click', function () {
    let point = $(this).attr('id').replace('star-', '');
    $('.review_rating').attr('value', point);
    $('.star_review').each(function () {
        $(this).removeClass('checked');
    });
    $(this).addClass('checked');
});
/* ------------------------------------ */

/* СБРАСЫВАНИЕ ФИЛЬТРА ПО КНОПКЕ RESET  */
$('#optionFilter').on('click', '[type="reset"]', function () {
    let name = $(this).attr('name');

    // очищение сессии
    $.get('/local/php_interface/ajax/clear_checkbox_session.php');
    viewData.filter_options = [];
    viewData.filter_assistans = [];
    viewData.filter_sport = [];

    // для всех radio
    // (!) не очищаем (!)
    /* let arr_name = [];
    let i = 0;
    $('.' + name).find($('input[type=radio]')).each(function () {
        if ($(this).attr('name') !== arr_name[i-1]) {
            arr_name[i] = $(this).attr('name');
            i++;
        }
        $(this).removeAttr('checked');
    });
    $('.' + name).find($('.radio_button__light')).each(function () {
        $(this).removeClass('checked');
    });
    for (let i = 0; i < arr_name.length; i++) {
        $('input[type=radio][name=' + arr_name[i] + ']').first().attr('checked', 'checked');
        $('.radio_button__light[name=' + arr_name[i] + ']').first().addClass('checked');
    } */

    // для всех checkbox
    $('.' + name).find($('input[type=checkbox]')).each(function () {
        $(this).removeAttr('checked');
    });
    $('.' + name).find($('.checkbox__light')).children($('div:first-child')).each(function () {
        $(this).removeClass('checked');
    });

    // для всех dropdown
    $('.' + name).find($('[data-dropdown-selecting]')).each(function () {
        let dropdownContainer = $(this).parent($('.dropdown_select'));
        let hiddenElement = dropdownContainer.find($('[type="hidden"]'));
        let dropdownValue = dropdownContainer.find($('.dropdown_value'));
        let name = hiddenElement.attr('name');
        hiddenElement.removeAttr('value').val();
        dropdownContainer.removeClass('selected');
        let defaultValue = dropdownValue.attr('default-value');
        dropdownValue.text(defaultValue);
    });
});
/* ------------------------------------ */

/* -- ОТОБРАЖЕНИЕ КОНТЕНТА В БЛОГАХ -- */
$(function () {
    // удаление градиента, если изображения нет в карточке
    $('.card_block__content').each(function () {
        let style = $(this).attr('style');
        if (style) {
            if (style.indexOf('background-image') + 1) {
                if ($(this).children('.card_block__cover').hasClass('card_block__default_blackout')) {
                    $(this).children('.card_block__cover').removeClass('card_block__gradient');
                }
            }
        } else {
            $(this).children('.card_block__cover').addClass('card_block__half_transparent');
            $(this).children('.card_block__cover').removeClass('card_block__gradient');
        }
    });
    // удаление текстового блока, если он не имеет контента
    // - это необходимо для избавления ненужного появляемого пространства в блоке
    $('.card_block__content').find($('p')).each(function () {
        if ($(this).text().trim() == '') $(this).css('display', 'none');
    });
});
/* ----------------------------------- */

function changePlaceholderOnMask (telNumber) {
    if (telNumber.length > 0) {
        telNumber.val('');
        let namePhone = telNumber.attr('name');
        let codePhone = $('.selected-dial-code').text();
        let mask = telNumber.attr('placeholder').replace(/[0-9]/g, 9);
        telNumber.mask(mask);
        let newPlaceholder = telNumber.attr('placeholder').replace(/[0-9]/g, '_');
        telNumber.attr('placeholder', newPlaceholder);
        $('[type=hidden][name="' + namePhone + '"]').attr('value', codePhone);
    }
};

$(function () {
    $('[type=tel]').intlTelInput({
        initialCountry: "RU",
        separateDialCode: true,
        preferredCountries: ['RU']
    });

    changePlaceholderOnMask($('[type=tel]'));
});

$('[type=tel]').on('countrychange', function (e, countryData) {
     let telNumber= $(this)
    changePlaceholderOnMask(telNumber);
});

// Круговая диаграмма на странице "Статистика по страховым случаям"
// на стр. со статистикой
function dataRating () {
    let result = [];
    for (let i = 0; i < data_chart_pie.length; i++) {
        result.push(
            {
                "name": data_chart_pie[i].name,
                "value": data_chart_pie[i].y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                "y": data_chart_pie[i].y,
                "color": data_chart_pie[i].color
            }
        );
    }
    return result;
}
$(function () {
    if ($('#highchart-rating-payments').length > 0) {
        Highcharts.chart('highchart-rating-payments', {
            title: {
                text: ""
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'pie',
                animation: false,
                hover: false,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                },
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    size: 360,
                    startAngle: -45,
                    center: ['55%', '200vw']
                },
                series: {
                    cursor: 'default',
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            enabled: false
                        },
                        select: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        padding: 8,
                        y: -10,
                        crop: false,
                        connectorShape: 'straight',
                        format: '{point.name},<br><span class="chart__value">{point.value} ₽</span>',
                        style: {
                            fontFamily: 'Manrope,sans-serif',
                            fontSize: '15px',
                            fontWeight: '400',
                            lineHeight: 20,
                            color: '#A9ADC1',
                            textOutline: 'none'
                        }
                    },
                    showInLegend: false
                }
            },
            legend: {
                labelFormatter: function () {
                    return this.name + ' — ' + this.value + ' ₽';
                },
                itemStyle: {
                    fontFamily: 'Manrope,sans-serif',
                    fontSize: '15px',
                    fontWeight: '400',
                    color: '#293365',
                    textOutline: 'none'
                },
                itemMarginBottom: 8
            },
            tooltip: {
                enabled: false
            },
            series: [
                {
                    innerSize: '50%',
                    data: dataRating ()
                }
            ],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 780
                    },
                    chartOptions: {
                        chart: {
                            height: 500,
                        }
                    }
                }, {
                    condition: {
                        maxWidth: 513
                    },
                    chartOptions: {
                        chart: {
                            height: 400,
                        },
                        plotOptions: {
                            pie: {
                                size: '260vw',
                                center: ['60%', '150vw']
                            }
                        }
                    }
                }, {
                    condition: {
                        maxWidth: 426
                    },
                    chartOptions: {
                        chart: {
                            height: 520,
                        },
                        plotOptions: {
                            pie: {
                                size: '280vw',
                                center: ['50%', '120vw']
                            },
                            series: {
                                dataLabels: {
                                    enabled: false,
                                    format: '{point.name},<br><span class="chart__value">{point.value} ₽</span>'
                                },
                                label: {
                                    format: '{point.name},<br><span class="chart__value">{point.value} ₽</span>'
                                },
                                showInLegend: true
                            }
                        }
                    }
                }]
            }
        });
    }
});

// переключение данных в таблице с количеством страховых случаев по странам мира
// на стр. со статистикой
$('[data-table-cases]').on('click', function () {
    let valueTableCase = $(this).attr('data-table-case');
    $('[data-' + valueTableCase + ']').show();
    $(this).addClass('active');
    switch (valueTableCase) {
        case 'countries_stat_ins':
            $('[data-cities_stat_accident]').hide();
            $('[data-table-case=cities_stat_accident]').removeClass('active');
            break;
        case 'cities_stat_accident':
            $('[data-countries_stat_ins]').hide();
            $('[data-table-case=countries_stat_ins]').removeClass('active');
            break;
    }
});

// кнопка "Открыть все записи"
$('.show_more_list').on('click', '[data-show-more]', function(e){
    let idValue = $(this).attr('data-id');
    let iblockValue = $(this).attr('data-iblock-id');
    let sendTo = $('[data-send-to-id=' + idValue + ']');
    let url = $(this).attr('data-url') + '/showMore.php';
    $.ajax({
        url: url,
        type: "GET",
        data: { iblock: iblockValue, code: idValue },
        dataType: "html",
        cache: false,
        success: function (data) {
            sendTo.append($(data));
        }
    });
    $(this).remove();
    return false;
});

// раскрывающиеся блоки типа "Вопрос-ответ"
$('.dropdown_block__question').on('click', function () {
    let answer = $(this).next($('.dropdown_block__answer'));
    let triangle = $(this).children($('.triangle'));
    if (triangle.hasClass('triangle__show')) {
        triangle.removeClass('triangle__show');
    } else {
        triangle.addClass('triangle__show');
    }
    if (answer.hasClass('show')) {
        answer.removeClass('show');
    } else {
        answer.addClass('show');
    }
});

// Событие по нажатию на кнопку "Показать ещё"
$(document).ready(function(){
    $(document).on('click', '[data-button-show-more]', function(){
        let targetContainer = $('.show_more_list');
        let url = $('.show_more').children('button').attr('data-url');
        if (url !== undefined) {
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'html',
                success: function(data){
                    $('.show_more').remove();
                    let elements = $(data).find('.show_more_item');
                    let pagination = $(data).find('.show_more');
                    targetContainer.append(elements);
                    targetContainer.after(pagination);
                }
            })
        }
    });
});

// --------------- РЕГИСТРАЦИЯ ---------------
$('[name="REGISTER[PASSWORD]"]').change(function () {
    let value = $(this).val();
    $('[name="REGISTER[CONFIRM_PASSWORD]"]').val(value);
});
$('[name="REGISTER[EMAIL]"]').change(function () {
    let value = $(this).val();
    $('[name="REGISTER[LOGIN]"]').val(value);
});

// ------------- ЛИЧНЫЙ КАБИНЕТ --------------
$('[name="EMAIL"]').keyup(function () {
    $('[name="LOGIN"]').attr('value', $(this).val());
});

$(function () {
    // округлая линия с процентом заполненности
    if ($('.card_payment__percent_line__in').length > 0) {
        let percentValue = $('.card_payment__percent_line__in').attr('style').replace('width: ', '').replace(/[px%]/g, '');
        percentValue = percentValue.indexOf(';') == -1 ? percentValue : percentValue.substr(0, percentValue.indexOf(';'));
        if (percentValue == 100) $('.card_payment__percent_line__in').addClass('complete');
    }

    // заполнение номера телефона при загрузке страницы
    if ($('[type="tel"]') && $('[type="tel"]').attr('value')) {
        $('[type="tel"]').each(function () {
            let name = $(this).attr('data-phone');
            let arrValue = $(this).attr('value').split(' ');
            let value = '';
            for (let i = 1; i < arrValue.length; i++) {
                value += arrValue[i] + ' ';
            }
            value = value.trim();
            $(this).attr('value', value);
            $(this).val(value);
            $('[type=hidden][name="' + name + '"]').attr('value', value);
            $('[type=hidden][name="' + name + '"]').val(value);
        });
    }

    // корректировка значения для поля с телефоном - для верной передачи из формы
    $('.intl-tel-input').ready(function () {
        $('.flag-container').find($('.country')).on('click', function () {
            let element = $(this).closest($('.intl-tel-input')).children($('input'));
            setPhoneNumber (element);
        });
        $('[type="tel"]').focusout(function () {
            setPhoneNumber ($(this));
        });
    });

    // смена вида кнопки при вводе данных в обязательные поля
    $('[data-required-form-submit]').keyup(function () {
        let check = true;
        let formId = $(this).attr('data-form-id');
        let value = $(this).val();
        let originalValue = $(this).attr('data-value');
        let name = $(this).attr('name');
        switch (name) {
            case 'NAME':
                check = value && checkOriginalValue(formId) ? check : false;
                break;
            case 'EMAIL':
                check = value.match('^$|^[a-z0-9._%+-]+@[a-z0-9.-]+\[.]{1}[a-z]{2,}$') && value && checkOriginalValue(formId) ? check : false;
                break;
            default:
                check = checkOriginalValue(formId);
        }
        if (check) $('[type="submit"][js-' + formId + '-submit]').prop("disabled", false);
        else $('[type="submit"][js-' + formId + '-submit]').prop("disabled", true);
    });
    // тоже самое для поля с датой отдельно
    $('.hasDatepicker[data-required-form-submit]').on('change', function () {
        checkOriginalDate($(this));
    });
    $('.hasDatepicker[data-required-form-submit]').focusout(function () {
        checkOriginalDate($(this));
    });
});

function setPhoneNumber (element) {
    let name = element.attr('data-phone');
    let phoneNumber = '';
    const intlTelInput = element.closest($('.intl-tel-input'));
    if (!(element.val().indexOf('_') + 1)) {
        phoneNumber = intlTelInput.find($('.selected-dial-code')).text() + ' ' + element.val();
        $('[type=hidden][name="' + name + '"]').val(phoneNumber);
    } else {
        $('[type=hidden][name="' + name + '"]').val('');
    }
}

function checkOriginalValue (formId) {
    let check = true;
    let count = 0;
    $('[data-form-id=' + formId + ']').each(function () {
        if ($(this).attr('type') == 'tel') {
            let fullPhoneNumber = $(this).parent($('.intl-tel-input')).find($('.selected-dial-code')).text();
            fullPhoneNumber += ' ' + $(this).val();
            if (fullPhoneNumber !== $(this).attr('data-value')) count++;
        } else if ($(this).val() !== $(this).attr('data-value')) {
            count++;
            if ($(this).attr('name') == 'EMAIL') {
                check = $(this).val() && $(this).val().match('^$|^[a-z0-9._%+-]+@[a-z0-9.-]+\[.]{1}[a-z]{2,}$') ? check : false;
            } else if ($(this).attr('name') == 'NAME') {
                check = $(this).val() ? check : false;
            }
        }
    });
    check = count > 0 ? check : false;
    return check;
}

function checkOriginalDate (element) {
    let formId = element.attr('data-form-id');
    let value = element.val();
    let originalValue = element.attr('data-value');
    if ((value !== originalValue) && !(value.indexOf('_') + 1)) $('[type="submit"][js-' + formId + '-submit]').prop("disabled", false);
    else $('[type="submit"][js-' + formId + '-submit]').prop("disabled", true);
}

// ------------- КНОПКИ ШАРИНГА --------------
$(function () {
    function externalLinks () {
        let links = $('a');
        links.each(function () {
            if ($(this).attr("href") && $(this).attr("data-link-sharing") == "true") {
                $(this).attr("onclick", "popupWin = window.open(this.href,'contacts','toolbar=0,status=0,width=626,height=436'); popupWin.focus(); return false");
            }
        });
    }
    window.onload = externalLinks();
});

// коррекция размеров блоков
$(function () {
    // Страница "Карьера" - фото по размеру блока с формой
    $('.send_resume__photo').height($('.send_resume__block').outerHeight());
});

//--------------Вывод опиций--------------------
$('.open-options').click(function(){
    $('.filter_options').show("normal");
    $('.open-options').hide('normal')
});
$('.close-options').click(function(){
    $('.filter_options').hide("normal");
    $('.open-options').show('normal');
    $("html, body").animate({
        scrollTop: $('.open-options').offset().top // прокручиваем страницу к требуемому элементу
    }, 500 ); // скорость прокрутки
});

// ---------- ПОДПИСКА НА РАССЫЛКУ ------------
// отправка формы подписки на рассылку
let isSendSubcribe = false;
$("form#promocode_form").submit(function(e){
    e.preventDefault();
    let isValide = $('#promocode_form').valid();
     if (isValide){
         if(!isSendSubcribe){
             isSendSubcribe = true;
             let form = $(this)[0];
             $.post("/local/php_interface/ajax/subscribe_to_news.php", $(this).serialize(), function (res){
                 const response = JSON.parse(res);
                 if(response.status === "success"){
                     form.reset();
                     $('[data-check-submit]').prop("disabled", true);
                     $("div[name=subscribe_checkbox]").removeClass('checked');
                     $('#subscribe_modal_notification').show();
                     $('[data-modal-close]').on('click', function () {
                         $('.modal_dialog').hide();
                         $('body').removeClass('modal-open');
                     });
                     setTimeout(function () {
                         $('.modal_dialog').hide();
                         $('body').removeClass('modal-open');
                     }, 4000);
                 } else {
                     $('body').append(modalStatusError);
                     setTimeout(function () {
                         $('#modal-status-error').hide();
                         $('body').removeClass('modal-open');
                     }, 1000);
                 }
             });
         }
    }
});

// ЯКОРИ: если якорь на элементе с раскрывающимся списком - раскрыть этот список
$(function () {
    // при обновлении страницы
    if ((window.location.hash.indexOf('anchor-') + 1) > 0) {
        $(window.location.hash).parent($('.dropdown_block__container')).find($('.dropdown_block__answer')).addClass('show');
    }
    // при нахождении на той же странице, что и якорь
    $('[data-top-menu-child]').on('click', 'a', function () {
        if (($(this).attr('href').indexOf('#anchor-') + 1) > 0) {
            $('.dropdown_block__answer').each(function () {
                $(this).removeClass('show');
            });
            let arrHref = $(this).attr('href').split('#');
            $('#' + arrHref[1]).parent($('.dropdown_block__container')).find($('.dropdown_block__answer')).addClass('show');
        }
    });
});

// Инициализация Slider для карточек предложений
$('.slider').slick({
    responsive: [
        {
            breakpoint: 426,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3
            }
        },
    ],
    infinite: true,
    dots: false,
    variableWidth: true,
    speed: 0,
    centerMode: true,
    draggable: false,
    focusOnSelect: true,
    prevArrow: $('.turn_left'),
    nextArrow: $('.turn_right')
});

// Инициализация Slider для фотографий
$('.photo-slider').slick({
    slidesToShow: 1,
    infinite: true,
    dots: true,
    variableWidth: true,
    speed: 0,
    centerMode: true,
    draggable: false,
    focusOnSelect: true,
    prevArrow: $('.turn_left'),
    nextArrow: $('.turn_right')
});

// Инициализация Slider для карточек программ страхования (в сравнительной таблице)
$('.slider_compare').slick({
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
            }
        }
    ],
    slidesToShow: 4,
    infinite: false,
    dots: false,
    variableWidth: true,
    speed: 0,
    centerMode: false,
    draggable: false,
    focusOnSelect: false,
    prevArrow: $('.compare_turn_left'),
    nextArrow: $('.compare_turn_right')
});

// Инициализация Slider для партнёров на гл.стр. (первый блок)
$('.partners-slider').slick({
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 4
            }
        }
    ],
    slidesToShow: 5,
    infinite: true,
    dots: false,
    variableWidth: true,
    speed: 0,
    centerMode: false,
    draggable: false,
    focusOnSelect: false,
    nextArrow: $('.partner_turn_right')
});

// Реализация добавления прозрачности крайним элементам слайдера
$('[data-slick-prev], [data-slick-next], [role="presentation"], .slick-slide').on('click', function () {
    $('.slider_element').removeClass('slider_transparent');
    $('.slick-slide').removeClass('slider_transparent');
    let centerSilde = $('.slick-active.slick-center');
    if ($(window).width() <= '1920') {
        centerSilde.prev().prev().addClass('slider_transparent');
        centerSilde.next().next().addClass('slider_transparent');
    }
    if ($(window).width() > '1920' && $(window).width() <= '2560') {
        centerSilde.prev().prev().prev().addClass('slider_transparent');
        centerSilde.next().next().next().addClass('slider_transparent');
    }
    let photoSlider = $('.photo-slider').children('.slick-list').children('.slick-track').children('.slick-active.slick-center');
    photoSlider.prev().addClass('slider_transparent');
    photoSlider.next().addClass('slider_transparent');
});

$(document).ready(function() {
    let centerSilde = $('.slick-active.slick-center');
    if ($(window).width() <= '1920') {
        centerSilde.prev().prev().addClass('slider_transparent');
        centerSilde.next().next().addClass('slider_transparent');
    }
    if ($(window).width() > '1920' && $(window).width() <= '2560') {
        centerSilde.prev().prev().prev().addClass('slider_transparent');
        centerSilde.next().next().next().addClass('slider_transparent');
    }

    let photoSlider = $('.photo-slider').children('.slick-list').children('.slick-track').children('.slick-active.slick-center');
    photoSlider.prev().addClass('slider_transparent');
    photoSlider.next().addClass('slider_transparent');
});

// ------------- ОФОРМЛЕНИЕ СТРАХОВОГО СЛУЧАЯ --------------
$(document).ready(function () {
    //              ШАГ-1
    $("button[name='sos-to-report']").click(function (e) {
        e.preventDefault();
        // let data = getDataInsuredPolicy($(this));
        let data = $(this).closest('.tr').find('[data-policy-id]').data();
        $.ajax({
            url: "/local/php_function/insurance-case-router.php",
            type: "POST",
            data: {
                'id_insuranse_policy': data.policyId,
                'action': 'step_1'
            },
            cache: true,
            dataType: 'json',
            success: function (data) {
                if (data['status'] === 200) {
                    if (data['company'] === 'ERV' || data['company'] === 'Ingosstrah') {
                        $('#sos_modal').show();
                    } else {
                        window.scrollTo(pageXOffset, 0);
                        $('.sos-progress-bar').scrollLeft(183);
                        let countSteps = 0;
                        $('.sos-progress-bar__step').each(function () {
                            countSteps++;
                            if ($(this).hasClass('active')) $(this).removeClass('active');
                            switch (countSteps) {
                                case 1:
                                    $(this).addClass('complete');
                                    break;
                                case 2:
                                    $(this).addClass('active');
                                    break;
                                default:
                                    break;
                            }
                        });

                        stepInInsuranseCase("next", 2);
                        $('form.insurance-case').children('input[name="secure_input"]').val(data['secure_code']);
                    }
                }
            },
            error: function error(xhr, status, _error) {
                console.log(xhr, status, _error);
            }
        });
    });
    //              ШАГ-2
    $("input[name='sos-to-registration']").click(function () {
        let isValide = $(".insurance-case").valid();
        if (isValide) {
            let arMess = usedMessangeres(); //Выбираем выбранные мессенджеры
            console.log(arMess);
            let phoneCodes = $(".selected-dial-code").text(); // строка с кодами телефонов
            let data = $('form.insurance-case').serializeArray();
            let secure_field = $('form.insurance-case').children('input[name="secure_input"]').val();
            $.ajax({
                url: "/local/php_function/insurance-case-router.php",
                type: "POST",
                data: {
                    'data_user_insured_case': data,
                    'messangeres': arMess,
                    'secure_code': secure_field,
                    'phone_region_codes': phoneCodes,
                    'action': 'step_2'
                },
                cache: true,
                dataType: 'json',
                success: function (data) {
                    if (data['status'] === 200) {
                        window.scrollTo(pageXOffset, 0);
                        $('.sos-progress-bar').scrollLeft(500);
                        let countSteps = 0;
                        $('.sos-progress-bar__step').each(function () {
                            countSteps++;
                            if ($(this).hasClass('active')) $(this).removeClass('active');
                            switch (countSteps) {
                                case 1:
                                    $(this).addClass('complete');
                                    break;
                                case 2:
                                    $(this).addClass('complete');
                                    break;
                                case 3:
                                    $(this).addClass('active');
                                    break;
                            }
                        });

                        $('form.insurance-date').children('input[name="secure_input"]').val(data['secure_code']);
                        addDataToStep3Table(data['userData']);
                        stepInInsuranseCase("next", 3);
                    }
                }
            });
        }
    });
    //              ШАГ-3
    $("input[name='sos-to-success']").click(function () {
        let secure_field = $('form.insurance-date').children('input[name="secure_input"]').val();
        $.ajax({
            url: "/local/php_function/insurance-case-router.php",
            type: "POST",
            data: {
                'secure_code': secure_field,
                'action': 'step_3'
            },
            cache: true,
            dataType: 'json',
            success: function (data) {
                if (data['status'] === 200) {

                    window.scrollTo(pageXOffset, 0);
                    let countSteps = 0;
                    $('.sos-progress-bar__step').each(function () {
                        countSteps++;
                        if ($(this).hasClass('active')) $(this).removeClass('active');
                        switch (countSteps) {
                            case 1:
                                $(this).addClass('complete');
                                break;
                            case 2:
                                $(this).addClass('complete');
                                break;
                            case 3:
                                $(this).addClass('active');
                                break;
                        }
                    });

                    if (data['company'] === 'ERV' || data['company'] === 'Ingosstrah') {
                        $('.insurance-alternativ').show()
                    } else {
                        $('.insurance-thanks').show()
                    }

                    stepInInsuranseCase("next", 4);
                }
            }
        });

    });

    //Сохраняем выбранные мессенджеры на шаге 2
    function usedMessangeres() {
        let result = ['', ''];
        $('.program-input').each(function () {
            if (($(this).prop("checked") && $(this).attr('name') === "insuredMessangerWhatsapp") || ($(this).prop("checked") && $(this).attr('name') === "insuredMessangerViber")) {
                if (result[0] !== "") {
                    result[0] += ", " + $(this).next('label').text();
                } else {
                    result[0] += $(this).next('label').text();
                }
            } else if (($(this).prop("checked") && $(this).attr('name') === "applicantMessangerWhatsapp") || ($(this).prop("checked") && $(this).attr('name') === "applicantMessangerViber")) {
                if (result[1] !== "") {
                    result[1] += ", " + $(this).next('label').text();
                } else {
                    result[1] += $(this).next('label').text();
                }
            }
        });
        return result;
    }

    function addDataToStep3Table(arData) {
        $('.insurance-date__table').find('[data-step3-value]').each(function () {
            let elemName = this.attributes["data-name"].value;
            if (elemName) {
                for (let objElem in arData) {
                    if (objElem === elemName) {
                        let objValue = arData[objElem];
                        $(this).text(objValue);
                    }
                }
            }
        })
    }

    //Переход по шагам
    function stepInInsuranseCase(step, id) {
        if (step === "next") {
            $("#sos-step" + (id - 1)).hide();
            $(".sos-progress-bar__icon").eq(id - 2).removeClass("current-step");
            $(".sos-progress-bar__number").eq(id - 2).removeClass('current-number');

            $("#sos-step" + (id)).show();
            $(".sos-progress-bar__icon").eq(id - 1).addClass("current-step");
            $(".sos-progress-bar__number").eq(id - 1).addClass('current-number');
        } else if (step === "prev") {
            $("#sos-step" + (id + 1)).hide();
            $(".sos-progress-bar__icon").eq(id).removeClass("current-step");
            $(".sos-progress-bar__number").eq(id).removeClass('current-number');

            $("#sos-step" + id).show();
            $(".sos-progress-bar__icon").eq(id - 1).addClass("current-step");
            $(".sos-progress-bar__number").eq(id - 1).addClass('current-number');
        }
    }

    $('.edit-button').click(function () {
        stepInInsuranseCase("prev", 2);
    });
});

//Поиск адреса по клику на карту + открытие карты
function openMap() {
    if ($('#map').css('display') == 'none') {
        $('#map').css('display', 'block');
        $('#map').css('margin-top', '40px');
        $('.map-change').text('Закрыть карту');
    } else {
        $('.map-change').text('Указать адрес на карте')
        $('#map').css('display', 'none');
    }
}

ymaps.ready(init);

function init() {
    var myPlacemark,
        myMap = new ymaps.Map('map', {
            center: [55.753994, 37.622093],
            zoom: 9
        }, {
            searchControlProvider: 'yandex#search'
        });

    // Слушаем клик на карте.
    myMap.events.add('click', function (e) {
        var coords = e.get('coords');

        // Если метка уже создана – просто передвигаем ее.
        if (myPlacemark) {
            myPlacemark.geometry.setCoordinates(coords);
        }
        // Если нет – создаем.
        else {
            myPlacemark = createPlacemark(coords);
            myMap.geoObjects.add(myPlacemark);
            // Слушаем событие окончания перетаскивания на метке.
            myPlacemark.events.add('dragend', function () {
                getAddress(myPlacemark.geometry.getCoordinates());
            });
        }
        getAddress(coords);
    });

    // Создание метки.
    function createPlacemark(coords) {
        return new ymaps.Placemark(coords, {
            iconCaption: 'поиск...'
        }, {
            preset: 'islands#violetDotIconWithCaption',
            draggable: true
        });
    }

    // Определяем адрес по координатам (обратное геокодирование).
    function getAddress(coords) {
        myPlacemark.properties.set('iconCaption', 'поиск...');
        ymaps.geocode(coords).then(function (res) {
            var firstGeoObject = res.geoObjects.get(0),
                address = firstGeoObject.getAddressLine();

            myPlacemark.properties
                .set({
                    // Формируем строку с данными об объекте.
                    iconCaption: [
                        // Название населенного пункта или вышестоящее административно-территориальное образование.
                        firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                        // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                        firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                    ].filter(Boolean).join(', '),
                    // В качестве контента балуна задаем строку с адресом объекта.
                    balloonContent: address
                });
            $('#place-and-date-accident').val(address);
            $('#place-and-date-accident').removeClass('error');
            $('#place-and-date-accident').focus();
        });
    }
}
